import React, { useState } from 'react';
import ErrorMessage from './ErrorMessage';
import CloseButton from 'react-bootstrap/CloseButton';
import styled from 'styled-components';

const MaxVideoSize = 100000000;
const StyledVideoUpload = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.4rem;

  .upload-file-input {
    display: none;
  }

  .upload-file {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: 0.2rem solid #ffffff;
    border-radius: 3rem;
    background: none;
    padding: 1rem 2.4rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    justify-self: end;
    flex: 0 0 auto;
  }

  .uploaded-file {
    height: fit-content;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    flex: 0 1 auto;
    word-break: break-all;
    text-align: right;
    vertical-align: middle;
  }

  .upload-file-wrapper-inner {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    justify-content: flex-end;
    align-items: center;
    flex: 10 1 auto;
  }

  @media only screen and (min-width: 720px) {
    .upload-file-wrapper-inner {
      justify-content: flex-start;
    }
  }
`;

const VideoUpload = ({ video, onChangeVideo }) => {
  const [responseMessage, setResponseMessage] = useState('');
  const handleFileChange = async e => {
    console.log(e.target.files[0]);
    if (e.target.files && e.target.files[0].size < MaxVideoSize) {
      onChangeVideo(e.target.files[0]);
      setResponseMessage('');
    } else {
      setResponseMessage('檔案大小超過100MB');
    }
  };

  const handleFileDelete = () => {
    onChangeVideo(null);
  };

  return (
    <StyledVideoUpload>
      <input
        className="upload-file-input"
        type="file"
        name="pdf-file"
        id="upload-pdf-file"
        accept="video/mp4"
        onClick={e => {
          e.target.value = null;
          console.log('Reset file');
        }}
        onChange={e => {
          handleFileChange(e);
        }}
      />
      <label className="upload-file" htmlFor="upload-pdf-file">
        上傳影片
      </label>
      <div className="upload-file-wrapper-inner">
        <label className="uploaded-file">{video ? `${video.name}` : ''}</label>
        <CloseButton
          style={{ display: video ? `block` : 'none' }}
          variant="white"
          onClick={() => handleFileDelete()}
        ></CloseButton>
      </div>
      {responseMessage && <ErrorMessage message={responseMessage} />}
    </StyledVideoUpload>
  );
};

export default VideoUpload;
