import React from 'react';
import ErrorMessage from './ErrorMessage';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

const StyledFormElement = styled.li`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background: none;
  }

  .half-element {
    width: 47%;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0;
    margin: 0;
    background: none;
  }

  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    background: none;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }
`;

const FormInput = ({ title, name, half = true, example, textarea = false }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <StyledFormElement className={`form-element ${half ? 'half-element' : ''}`}>
      <h3 className="element-title">{title}</h3>
      {textarea && (
        <textarea
          className="element-input more-description"
          type="text"
          autoComplete="on"
          placeholder={`Ex: ${example}`}
          {...register(name)}
        />
      )}
      {!textarea && (
        <input
          className="element-input"
          type="text"
          autoComplete="on"
          placeholder={`Ex: ${example}`}
          {...register(name)}
        />
      )}
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </StyledFormElement>
  );
};

export default FormInput;
