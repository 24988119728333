import React, { useState, useContext, useEffect } from 'react';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import collapseBefore from 'assets/images/collapseBefore.svg';
import ReactPaginate from 'react-paginate';
import Accordion from 'react-bootstrap/Accordion';
import { useHttpClient } from 'shared/hooks/http-hook';
import styled from 'styled-components';
import { AuthContext } from 'shared/context/auth-context';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const AccordionWrapper = styled(Accordion)`
  .accordion-item {
    border: none;
  }
  .accordion-body {
    background: #1c1c1c;
    border-radius: 0px;
    > ul {
      display: flex;
      flex-wrap: wrap;
      background: #010001;
      border-radius: 16px;
      padding: 1.2rem 1.6rem;

      > li {
        width: 100%;
        display: flex;
        gap: 3.2rem;
        background: none;
        padding: 0.8rem;
        label {
          min-width: 8rem;
          font-family: 'Albert Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #969696;
          background: none;
        }

        h5 {
          font-family: 'Albert Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
          background: none;
          overflow-wrap: anywhere;
          /* margin-left: auto; */
          text-align: left;
        }
      }
    }

    > div {
      background: none;
      > div {
        display: flex;
        gap: 3.2rem;
        padding: 0 2rem;
        background: none;

        margin-bottom: 1.6rem;
        img {
          width: 100%;
          height: 100%;
        }

        .name-price-wrapper {
          display: flex;
          flex-direction: column;
          background: none;
          justify-items: space-between;
          align-items: flex-start;
          width: 100%;

          h2 {
            font-family: 'Albert Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #ffffff;
            background: none;
          }

          h3 {
            font-family: 'Albert Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: left;
            display: flex;
            align-items: center;
            color: #ffffff;
            background: none;
          }
        }
      }
    }
  }

  .accordion-button {
    width: 100%;
    background: none;
    border: none;
    padding: 0 1.25rem;

    &.collapsed {
      margin: 0;
      border: none;
      ::after {
        position: absolute;
        right: 3rem;
        background-image: url(${collapseBefore});
        background-size: 2rem 2rem;
        background-position-x: 50%;
      }
    }

    &:not(.collapsed)::after {
      position: absolute;
      right: 3rem;
      background-image: url(${collapseBefore});
      background-size: 2rem 2rem;
      background-position-x: 50%;
    }

    :focus {
      box-shadow: none;
      border-color: black;
      background: #1c1c1c;
      border-radius: 0px;
    }

    > div {
      padding: 1.2rem 0;
      margin: 0 1.2rem;
      border-bottom: 0.2rem solid #262626;
      width: 100%;
      height: 6.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 0rem;
      gap: 3rem;
      background: none;

      h6 {
        width: 33%;
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        margin: auto;
        background: none;
        word-wrap: break-word;
      }

      .date-time {
        display: flex;
        flex-direction: column;
        width: 33%;
        margin: auto;
        padding: 0;
        background: none;
        > h6 {
          width: 100%;
        }
      }
    }
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .accordion-button {
    &.collapsed:focus {
      border-radius: 0px 0px 0px 0px;
    }
    :hover {
      border-radius: 0px 0px 0px 0px;
      background: #1c1c1c;
    }
  }
  @media only screen and (min-width: 900px) {
    .accordion-body {
      border-radius: 0px 0px 16px 16px;
      > ul {
        margin: 1rem 1rem;
        > li:not(:last-child) {
          width: 50%;
          label {
            font-size: 16px;
            line-height: 20px;
          }
          h5 {
            font-size: 16px;
            line-height: 20px;
            margin-left: 0;
            margin-bottom: 0;
          }
        }
      }
      > div {
        > div {
          margin-bottom: 1.6rem;
          img {
            width: 100%;
            height: 100%;
          }
          .name-price-wrapper {
            flex-direction: row;
            align-items: center;
            h2 {
              font-size: 16px;
              line-height: 24px;
            }
            h3 {
              font-size: 16px;
              line-height: 24px;
              text-align: right;
              margin-left: auto;
            }
          }
        }
      }
    }
    .accordion-button {
      :focus {
        border-radius: 16px 16px 0px 0px;
      }
      > div {
        h6 {
          font-size: 16px;
          line-height: 24px;
        }
        .date-time {
          flex-direction: row;
          gap: 1rem;
        }
      }
    }
  }
`;

const StyledReactSearchAutocomplete = styled.div`
  margin: 3rem;
  * {
    background-color: transparent;
  }
  .ellipsis {
    color: #ffffff;
    span {
      color: #ffffff;
    }
  }

  .wrapper {
    /* display: none; */
    .line {
      display: none;
    }
    div:nth-child(2) {
      display: none;
    }
  }
`;

const ServiceItems = ({ currentItems, reservations }) => {
  return (
    <>
      <AccordionWrapper className="border-0">
        {currentItems.map((service, index) => (
          <Accordion.Item key={index} className="border-0" eventKey={index}>
            <Accordion.Header className="border-0">
              <div>
                <div className="date-time">
                  <h6>{service.created_at}</h6>
                </div>
                <h6>{service.name}</h6>
                <h6>
                  {service.brand} {service.model}
                </h6>
                <h6>{service.type}</h6>
              </div>
            </Accordion.Header>
            <Accordion.Body className="border-0">
              <ul>
                <li>
                  <label>出廠年份</label>
                  <h5>{service.year}</h5>
                </li>
                <li>
                  <label>Line ID</label>
                  <h5>{service.line}</h5>
                </li>
                <li>
                  <label>CC數</label>
                  <h5>{service.cc}</h5>
                </li>
                <li>
                  <label>引擎燃料</label>
                  <h5>{service.fuel}</h5>
                </li>
                <li>
                  <label>外觀顏色</label>
                  <h5>{service.appearance}</h5>
                </li>
                <li>
                  <label>內裝顏色</label>
                  <h5>{service.interior}</h5>
                </li>
                <li>
                  <label>里程數</label>
                  <h5>{service.mileage}</h5>
                </li>
                <li>
                  <label>一手車至今</label>
                  <h5>{service.new}</h5>
                </li>
                <li>
                  <label>補充說明</label>
                  <h5>{service.notes}</h5>
                </li>
              </ul>
              <div>
                {service.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={`${process.env.REACT_APP_DATA_URL}/uploads/service/${image}`}
                      alt="car"
                    />
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </AccordionWrapper>
    </>
  );
};

const ServiceWrapper = styled.div`
  && {
    width: 100%;
    height: 5.2rem;
    background: #1c1c1c;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    header {
      width: 25%;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #969696;
      background: none;
    }
  }
`;

const ServicePaginateWrapper = styled(ReactPaginate)`
  && {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 8px;
    padding-top: 3.2rem;
  }

  .page-link {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background-color: transparent;
    border-radius: 14px;
    border: transparent;
    color: #969696;
    user-select: none;
  }

  .page-active {
    background: #1c1c1c;
    border-radius: 14px;
    border: transparent;
    box-shadow: none;
    color: white;
  }

  .page-previous,
  .page-next {
    font-weight: 200;
    font-size: 18px;
    color: white;
    text-decoration: none;
    user-select: none;
  }
`;

const Service = ({ refetch, setRefetch }) => {
  const itemsPerPage = 20;
  // console.log(item);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;

  const [services, setServices] = useState([]);
  const [searchedServices, setSearchedServices] = useState([]);

  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const auth = useContext(AuthContext);

  const fetchService = async () => {
    try {
      setIsLoading(true);
      const serviceData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/service/all',
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );

      // console.log(serviceData);
      serviceData.forEach(item => {
        item.created_at = new Date(item.created_at).toLocaleString('zh-TW');

        if (item.fuel === 'gas') {
          item.fuel = '汽油';
        } else if (item.fuel === 'diesel') {
          item.fuel = '柴油';
        } else if (item.fuel === 'electric') {
          item.fuel = '純電';
        } else {
          item.fuel = '油電';
        }

        if (item.type === 'sale') {
          item.type = '委託銷售';
        } else {
          item.type = '線上估價';
        }

        if (item.new === true) {
          item.new = '是';
        } else {
          item.new = '否';
        }
      });

      setServices(serviceData);
      setSearchedServices(serviceData);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchServiceWrapper = async () => {
    await fetchService();
  };
  useEffect(() => {
    // console.log("Fetch service data");
    if (!refetch) return;
    fetchServiceWrapper();
    setRefetch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // Note: due to `Every returned JSX element must be only one JSX element`,
  //       Tab cannot be included in this function
  const currentItems = searchedServices.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(searchedServices.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % searchedServices.length;
    setItemOffset(newOffset);
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log("onSearch", string, results);
    if (string === '') {
      setSearchedServices(services);
    } else if (results.length > 0) {
      setSearchedServices(results);
    }
  };

  const handleOnHover = result => {
    // the item hovered
    // console.log(result);
  };

  const handleOnSelect = item => {
    // the item selected
    // console.log("onSelect", item);
    // console.log(item);
  };

  const handleOnFocus = () => {
    // console.log("Focused");
  };

  const formatResult = item => {
    return (
      <React.Fragment>
        <span style={{ display: 'block', textAlign: 'left' }}>
          {/* {item.reservation_date} &nbsp; {item.reservation_time} &nbsp;{" "}
          {item.name} */}
        </span>
      </React.Fragment>
    );
  };

  // console.log("services", services);
  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && !!services && (
        <React.Fragment>
          <StyledReactSearchAutocomplete>
            <ReactSearchAutocomplete
              items={services}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              maxResults={services.length}
              formatResult={formatResult}
              showNoResultsText="無搜尋結果"
              placeholder="輸入關鍵字"
              resultStringKeyName="name"
              fuseOptions={{
                keys: [
                  'appearance',
                  'brand',
                  'cc',
                  'created_at',
                  'interior',
                  'line',
                  'mileage',
                  'model',
                  'name',
                  'new',
                  'notes',
                  'type',
                  'year',
                ],
              }}
              styling={{
                backgroundColor: '#000000',
                borderRadius: '2.4rem',
                border: '2px solid #969696',
                fontFamily: 'Albert Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '1.6rem',
                color: '#ffffff',
                iconColor: '#ffffff',
                lineHeight: '1.8rem',
                hoverBackgroundColor: '#1c1c1c',
                zIndex: '998',
                lineColor: '#969696',
                placeholderColor: '#969696',
              }}
            />
          </StyledReactSearchAutocomplete>
          <ServiceWrapper>
            <header>送出時間</header>
            <header>姓名</header>
            <header>廠牌型號</header>
            <header>要求服務</header>
          </ServiceWrapper>
          <ServiceItems currentItems={currentItems} />
          <ServicePaginateWrapper
            className="pagination"
            pageLinkClassName="page-link"
            activeLinkClassName="page-active"
            previousLinkClassName="page-previous"
            nextLinkClassName="page-previous"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Service;
