import React, { createContext } from 'react';

export const InventoryComponentRenderState = {
  AllInventories: 0,
  EditInventory: 1,
  NewInventory: 2,
};

export const DataContextInventory = createContext(
  InventoryComponentRenderState.AllInventories,
);

export const TopicComponentRenderState = {
  AllTopics: 0,
  EditTopic: 1,
  NewTopic: 2,
};

export const DataContextTopic = createContext(
  TopicComponentRenderState.AllTopics,
);
