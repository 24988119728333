import React, { useContext } from 'react';
import { InventoryComponentRenderState } from 'pages/States';
import styled from 'styled-components';
import { useHttpClient } from 'shared/hooks/http-hook';
import { AuthContext } from 'shared/context/auth-context';

const InventoryCardWrapper = styled.div`
  && {
    width: 100%;
    border-radius: 16px;
    background: #1c1c1c;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .text-wrapper {
    padding: 0 2.4rem 2rem 2.4rem;
    background: none;
  }

  .contact-title-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2.4rem;
    padding-top: 2rem;
    background: none;

    .contact-title {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #ffffff;
      padding: 0;
      background: none;
    }
  }

  .contact-info {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 0;
    background: none;
  }

  .btn-wrapper {
    width: 100%;
    border-top: 0.2rem solid black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 1.2rem;
    background: none;
    gap: 3rem;
    padding: 1.2rem 2.4rem;
  }

  .btn-remove {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #969696;
    background: none;
  }

  .btn-edit {
    width: 68px;
    height: 40px;
    background: #010001;
    border-radius: 30px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }

  .car-image {
    width: 100%;
    height: 20rem;
    object-fit: contain;
    background-color: transparent;
  }

  @media only screen and (min-width: 900px) {
    && {
      width: 48%;
    }
  }
`;

const InventoryCardPanelWrapper = styled.div`
  && {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.4rem;
    padding-bottom: 4.4rem;
  }
`;

const InventoryCard = ({
  item,
  setShowInventory,
  setCurrentInventoryItem,
  inventoryItems,
  setInventoryItems,
  setRefetch,
}) => {
  const { error, sendRequest, isLoading, setIsLoading } = useHttpClient();

  const auth = useContext(AuthContext);
  const handleRemoveItem = async id => {
    setIsLoading(true);
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/inventory/delete',
        'POST',
        JSON.stringify({
          id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      );
    } catch (err) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    setInventoryItems(inventoryItems.filter(item => item.id !== id));
  };

  const handleEditInventoryItem = item => {
    setShowInventory(InventoryComponentRenderState.EditInventory);
    setCurrentInventoryItem(item);
    localStorage.setItem('inventoryItemId', item.id);
  };

  return (
    <InventoryCardWrapper>
      <div className="text-wrapper">
        <div className="contact-title-wrapper">
          <h1 className="contact-title">{item.title}</h1>
          <h1 className="contact-title">{item.price}</h1>
        </div>
        <h2 className="contact-info">{item.subtitle}</h2>
      </div>
      <img
        className="car-image"
        src={`${process.env.REACT_APP_DATA_URL}/inventory/${item.image}`}
        alt=""
      />
      <div className="btn-wrapper">
        <button
          className="btn-remove"
          onClickCapture={() => handleRemoveItem(item.id)}
        >
          移除
        </button>
        <button
          onClick={() => {
            handleEditInventoryItem(item);
            setRefetch(true);
          }}
          className="btn-edit"
        >
          編輯
        </button>
      </div>
    </InventoryCardWrapper>
  );
};

const InventoryCardPanel = ({
  searchedInventoryItems,
  setShowInventory,
  setCurrentInventoryItem,
  inventoryItems,
  setInventoryItems,
  setRefetch,
}) => {
  const { isLoading } = useHttpClient();

  return (
    !isLoading && (
      <InventoryCardPanelWrapper>
        {searchedInventoryItems.map((item, index) => (
          <InventoryCard
            setRefetch={setRefetch}
            key={index}
            item={item}
            setShowInventory={setShowInventory}
            setCurrentInventoryItem={setCurrentInventoryItem}
            inventoryItems={inventoryItems}
            setInventoryItems={setInventoryItems}
          />
        ))}
      </InventoryCardPanelWrapper>
    )
  );
};

export default InventoryCardPanel;
