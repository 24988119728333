import React, { createContext, useState } from 'react';

export const RefetchContext = createContext();

export const RefetchContextProvider = ({ children }) => {
  const [refetchTopics, setRefetchTopics] = useState(false);
  const [refetchHome, setRefetchHome] = useState(false);
  const [refetchInventory, setRefetchInventory] = useState(false);
  const [refetchHistory, setRefetchHistory] = useState(false);
  const [refetchMember, setRefetchMember] = useState(false);
  const [refetchService, setRefetchService] = useState(false);
  const [refetchContact, setRefetchContact] = useState(false);
  const [refetchAboutUs, setRefetchAboutUs] = useState(false);
  const [refetchOffers, setRefetchOffers] = useState(false);
  const [refetchBg, setRefetchBg] = useState(false);

  return (
    <RefetchContext.Provider
      value={{
        refetchTopics,
        refetchHome,
        refetchInventory,
        refetchHistory,
        refetchMember,
        refetchService,
        refetchContact,
        refetchAboutUs,
        refetchOffers,
        refetchBg,
        setRefetchTopics,
        setRefetchHome,
        setRefetchInventory,
        setRefetchHistory,
        setRefetchMember,
        setRefetchService,
        setRefetchContact,
        setRefetchAboutUs,
        setRefetchOffers,
        setRefetchBg,
      }}
    >
      {children}
    </RefetchContext.Provider>
  );
};
