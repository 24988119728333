import styled from 'styled-components';
import inventoryEmptyIcon from 'assets/images/item-empty.svg';

const EmptyInventoryWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44.6rem;
    gap: 1.5rem;
    background: #1c1c1c;
    border-radius: 16px;
  }

  .empty-inventory-icon {
    width: 4.2rem;
    height: 4.2rem;
    background: none;
  }

  .empty-inventory-label {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background: none;
  }
`;

const EmptyInventory = ({ label }) => {
  return (
    <EmptyInventoryWrapper>
      <img className="empty-inventory-icon" src={inventoryEmptyIcon} alt="" />
      <h3 className="empty-inventory-label">{label}</h3>
    </EmptyInventoryWrapper>
  );
};

export default EmptyInventory;
