import React, { useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Controller } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';

const Header = styled.h1`
  display: block;
  margin: auto;
  padding: 8rem 0 4rem 0;
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
`;

const Car = styled.div`
  height: 40rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const ImgSwiper = styled(Swiper)`
  height: 100%;
  max-width: 120rem;

  .swiper-button-prev,
  .swiper-button-next {
    width: 4.8rem;
    height: 4.8rem;
    background: rgba(1, 0, 1, 0.5);
    ::after {
      color: white;
      font-size: 16px;
    }
  }

  .swiper-slide {
    height: 44rem;
    text-align: center;
    font-size: 18px;
    background: transparent;
  }
`;

const TextSwiper = styled(Swiper)`
  padding-bottom: 8rem;
  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4rem;
    gap: 1.6rem;
    max-width: 40rem;
    height: 18rem;
    background: transparent;
    .title {
      max-width: 32rem;
      height: 2.4rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #4e4e4e;
      background: transparent;
    }

    .text {
      max-width: 32rem;
      height: 4rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 2rem;
      color: #4e4e4e;
      background: transparent;
    }
  }
  .swiper-slide-active {
    background: linear-gradient(180deg, #1c1c1c 0%, rgba(28, 28, 28, 0) 100%);
    .title {
      color: #ffffff;
    }
    .text {
      color: #ffffff;
    }
  }
`;

const CarouselTopic = ({
  characteristics,
  carImages,
  header = '經典特色（預覽）',
}) => {
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [controlledTextSwiper, setControlledTextSwiper] = useState(null);
  return (
    <React.Fragment>
      <Header>{header}</Header>
      <ImgSwiper
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={10}
        grabCursor={true}
        modules={[Controller, Navigation]}
        controller={{ control: controlledSwiper }}
        navigation
        scrollbar={{ draggable: true }}
        onSwiper={setControlledTextSwiper}
      >
        {carImages.map((car, index) => (
          <SwiperSlide key={index}>
            <Car style={{ backgroundImage: `url(${car})` }} />
          </SwiperSlide>
        ))}
      </ImgSwiper>
      <TextSwiper
        slidesPerView="auto"
        centeredSlides={true}
        spaceBetween={10}
        grabCursor={true}
        modules={[Controller]}
        onSwiper={setControlledSwiper}
        controller={{ control: controlledTextSwiper }}
        slideToClickedSlide={true}
      >
        {characteristics.map((character, index) => (
          <SwiperSlide type="button" key={index}>
            <div className="title">{character.title}</div>
            <div className="text">{character.description}</div>
          </SwiperSlide>
        ))}
      </TextSwiper>
    </React.Fragment>
  );
};

export default CarouselTopic;
