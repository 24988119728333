import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import ErrorMessage from 'shared/components/FormElements/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import { AuthContext } from 'shared/context/auth-context';
import FormInput from 'shared/components/FormElements/FormInput';
import ImageUpload from 'shared/components/FormElements/ImageUpload';
import CarouselTopic from '../topic/CarouselTopic';
import { urlToObject } from 'shared/utils/urlToObject';

const StyledSection = styled.section`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .sub-form-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    width: 100%;
    max-width: 640px;
    background: rgba(1, 0, 1, 0.4);
    backdrop-filter: blur(4px);
    border-radius: 1.6rem;
    margin: auto;
  }

  .sub-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.4rem;
    padding: 0;
    background: none;
  }

  .characteristics-wrapper {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.6rem;
    /* background: #1c1c1c; */
    border-radius: 16px;
    border: 0.3rem solid #969696;

    .characteristics-image-upload-wrapper {
      background: #1c1c1c;
    }

    .btn-characteristics-wrapper {
      display: flex;
      gap: 1.6rem;
      align-self: end;
      .btn-add,
      .btn-remove {
        align-self: flex-end;
        width: 15.6rem;
        height: 4.8rem;
        border: 2px solid #ffffff;
        border-radius: 24px;
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        background-color: #cd0715;
      }
      .btn-remove {
        background-color: #1c1c1c;
      }
    }
  }

  .flex {
    display: flex;
  }

  .form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background: none;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0;
    margin: 0;
    background: none;
  }

  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    background: none;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .more-description {
    padding: 1.2rem 1.6rem;
    height: 9.6rem;
    resize: none;
  }

  .upload-file {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: 0.2rem solid #ffffff;
    border-radius: 3rem;
    background: none;
    padding: 1rem 2.4rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    justify-self: end;
    flex: 0 0 auto;
  }

  .uploaded-file {
    height: fit-content;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    flex: 0 1 auto;
    word-break: break-all;
    text-align: right;
    vertical-align: middle;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    padding-top: 5.6rem;
    padding-bottom: 3.2rem;
  }

  .btn-back,
  .btn-confirm {
    width: 15.6rem;
    height: 4.8rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .btn-confirm {
    background: #cd0715;
  }

  .width-100 {
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 720px) {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0rem;
    border-bottom: 2px solid #262626;

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3.2rem;
    }

    .half-element {
      width: 47%;
    }

    .btn-wrapper {
      padding-top: 4rem;
    }
  }
`;

const OffersForm = ({ buttonName, refetch, setRefetch }) => {
  // Create the states/setState for the above fields
  const [offersItem, setOffersItem] = useState(null);
  const [bannerImage, setBannerImage] = useState([]);
  const [sectionOneLeftImg, setSectionOneLeftImg] = useState([]);
  const [sectionOneRightImg, setSectionOneRightImg] = useState([]);
  const [sectionTwoLeftImg, setSectionTwoLeftImg] = useState([]);
  const [sectionTwoRightImg, setSectionTwoRightImg] = useState([]);
  const [characteristicsImage, setCharacteristicsImage] = useState([]);
  const [characteristicsItems, setCharacteristicsItems] = useState([]);
  const [characteristicMessage, setCharacteristicMessage] = useState('');
  const [characteristicsImages, setCharacteristicsImages] = useState([]);
  const charLength = 1023;
  // create the formSchema based on the above schema
  const formSchema = yup.object().shape({
    banner_title: yup
      .string()
      .required('標題為必填')
      .max(charLength, `標題最長為${charLength}字`),
    banner_subtitle: yup
      .string()
      .required('副標題為必填')
      .max(charLength, `副標題最長為${charLength}字`),
    banner_paragraph: yup
      .string()
      .required('副標題內容為必填')
      .max(charLength, `副標題內容最長為${charLength}字`),
    section_1_subtitle: yup
      .string()
      .required('章節1標題為必填')
      .max(charLength, `章節1標題最長為${charLength}字`),
    section_1_description: yup
      .string()
      .required('章節1內容為必填')
      .max(charLength, `章節1內容最長為${charLength}字`),
    section_2_subtitle: yup
      .string()
      .required('章節2標題為必填')
      .max(charLength, `章節2標題最長為${charLength}字`),
    section_2_description: yup
      .string()
      .required('章節2內容為必填')
      .max(charLength, `章節2內容最長為${charLength}字`),
  });

  const characteristicFormSchema = yup.object().shape({
    title: yup
      .string()
      .required('特色標題為必填')
      .max(charLength, `特色標題最長為${charLength}`),
    description: yup
      .string()
      .required('特色敘述為必填')
      .max(charLength, `色敘述最長為${charLength}字`),
  });

  const characteristicFormMethods = useForm({
    resolver: yupResolver(characteristicFormSchema),
  });

  const {
    reset: characteristicReset,
    handleSubmit: handleCharacteristicSubmit,
  } = characteristicFormMethods;

  const formMethods = useForm({ resolver: yupResolver(formSchema) });
  const {
    setValue,
    formState: { isDirty },
    handleSubmit,
  } = formMethods;

  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    if (isDirty) {
      setResponseMessage('');
    }
  }, [isDirty]);

  const auth = useContext(AuthContext);

  const setDefaultItem = async () => {
    try {
      setIsLoading(true);
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/offers',
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );
      console.log('responseData', responseData);
      setOffersItem(responseData[0]);
      const currentItem = responseData[0];
      if (!!currentItem) {
        for (let name in currentItem) {
          setValue(name, currentItem[name]);
        }
        // Use Promise.all to wait for all the urlToObject calls to complete
        const [
          bannerImageObj,
          sectionOneLeftImgObj,
          sectionOneRightImgObj,
          sectionTwoLeftImgObj,
          sectionTwoRightImgObj,
        ] = await Promise.all([
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.banner_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.section_1_left_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.section_1_right_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.section_2_left_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.section_2_right_img}`,
          ),
        ]);
        setBannerImage([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.banner_img}`,
            file: bannerImageObj,
          },
        ]);
        setSectionOneLeftImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.section_1_left_img}`,
            file: sectionOneLeftImgObj,
          },
        ]);
        setSectionOneRightImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.section_1_right_img}`,
            file: sectionOneRightImgObj,
          },
        ]);
        setSectionTwoLeftImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.section_2_left_img}`,
            file: sectionTwoLeftImgObj,
          },
        ]);
        setSectionTwoRightImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/offers/${currentItem.section_2_right_img}`,
            file: sectionTwoRightImgObj,
          },
        ]);
        // Combine currentTopicItem.characteristics_titles and set the characteristicsItems
        setCharacteristicsItems(
          currentItem.characteristics_titles.map((item, index) => {
            return {
              title: item,
              description: currentItem.characteristics_descriptions[index],
            };
          }),
        );

        setCharacteristicsImages(
          await Promise.all(
            currentItem.characteristics_images.map(async item => {
              const file = await urlToObject(
                `${process.env.REACT_APP_DATA_URL}/uploads/offers/${item}`,
              );
              return {
                data_url: `${process.env.REACT_APP_DATA_URL}/uploads/offers/${item}`,
                file,
              };
            }),
          ),
        );
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const setDefaultItemWrapper = async () => {
    await setDefaultItem();
  };

  const handleCharacteristicsDeleteAll = e => {
    e.preventDefault();
    setCharacteristicMessage('');
    characteristicReset({
      title: '',
      description: '',
    });
    setCharacteristicsItems([]);
    setCharacteristicsImages([]);
  };

  const onCharacteristicsSubmit = async data => {
    if (characteristicsImage.length === 0) {
      setCharacteristicMessage('請上傳圖片');
      return;
    }
    // Append the new characteristic to the existing ones
    setCharacteristicsItems(previousItems => {
      if (previousItems.length === 0) {
        // If there is no characteristic yet
        return [{ title: data.title, description: data.description }];
      } else {
        // If there are characteristics already
        return [
          ...previousItems,
          { title: data.title, description: data.description },
        ];
      }
    });
    setCharacteristicsImages(previousImages => {
      if (previousImages.length === 0) {
        // If there is no characteristic yet
        return [characteristicsImage[0]];
      } else {
        // If there are characteristics already
        return [...previousImages, characteristicsImage[0]];
      }
    });
    setCharacteristicMessage('');
    characteristicReset({
      title: '',
      description: '',
    });
    setCharacteristicsImage([]);
  };

  const onSubmit = async data => {
    try {
      const formData = new FormData();
      if (!!offersItem) {
        formData.append('id', data.id);
      }
      formData.append('banner_title', data.banner_title);
      formData.append('banner_subtitle', data.banner_subtitle);
      formData.append('banner_paragraph', data.banner_paragraph);
      formData.append('section_1_subtitle', data.section_1_subtitle);
      formData.append('section_1_description', data.section_1_description);
      formData.append('section_2_subtitle', data.section_2_subtitle);
      formData.append('section_2_description', data.section_2_description);
      formData.append(
        'characteristics_titles',
        JSON.stringify(characteristicsItems.map(item => item.title)),
      );
      formData.append(
        'characteristics_descriptions',
        JSON.stringify(characteristicsItems.map(item => item.description)),
      );
      // Note: the order of the images is important
      formData.append('image', bannerImage[0].file);
      formData.append('image', sectionOneLeftImg[0].file);
      formData.append('image', sectionOneRightImg[0].file);
      formData.append('image', sectionTwoLeftImg[0].file);
      formData.append('image', sectionTwoRightImg[0].file);
      for (const image of characteristicsImages) {
        formData.append('image', image.file);
      }
      const responseData = await sendRequest(
        !!offersItem
          ? process.env.REACT_APP_BACKEND_URL + '/admin/offers/update'
          : process.env.REACT_APP_BACKEND_URL + '/admin/offers/new',
        'POST',
        formData,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );

      // Set the response message
      setRefetch(true);
      setResponseMessage(responseData.message);
    } catch (err) {
      console.log(err);
      setResponseMessage(
        '請確認所有必填欄位皆已填寫，圖片上傳正確，且格式正確',
      );
    }
  };

  useEffect(() => {
    if (!refetch) return;
    setDefaultItemWrapper();
    setRefetch(false);
  }, [refetch, responseMessage]);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <StyledSection>
          <FormProvider {...formMethods}>
            <form
              className="sub-form-wrapper"
              onSubmit={handleSubmit(onSubmit)}
            >
              <ul className="sub-form">
                {/** BANNER */}
                <FormInput
                  title="標題"
                  name="banner_title"
                  example="服務項目"
                />
                <FormInput
                  title="副標題"
                  name="banner_subtitle"
                  example="De Tomaso Partners Up With Capricorn Group To Build The Upcoming P72 Supercar At The Nürburgring"
                  half={false}
                />
                <FormInput
                  half={false}
                  title="副標題內容"
                  name="banner_paragraph"
                  example="The history of De Tomaso is steeped in international collaborations. Take the perennially popular Pantera for example, which was the result of an American designer drawing sports cars in Turin (Tom Tjaarda at Ghia) to be powered by V8s from Ohio (the Ford Cleveland engines), all under the direction of Alejandro de Tomaso, who came to Europe from Argentina to build sports and racing cars in the late 1950s. Today, the company’s tradition of global partnerships is being carried forward with a brand new strategic partnership, this time between the revived De Tomaso marque and longtime German motorsport chassis and component developer, capricorn Group (responsible in part for the Porsche 919 LMP1 triple Le Mans-winner, for starters)."
                />
                <ImageUpload
                  title="標題圖片"
                  maxNumber={1}
                  maxFileSize={8000000}
                  images={bannerImage}
                  onChangeImages={setBannerImage}
                />
                {/** SECTION 1 */}
                <FormInput
                  title="章節1 標題"
                  name="section_1_subtitle"
                  example="預約賞車"
                />
                <FormInput
                  title="章節1 內容"
                  half={false}
                  name="section_1_description"
                  example="911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。"
                />
                <div className="flex">
                  <ImageUpload
                    title="章節1 左圖"
                    maxNumber={1}
                    maxFileSize={8000000}
                    images={sectionOneLeftImg}
                    onChangeImages={setSectionOneLeftImg}
                  />
                  <ImageUpload
                    title="章節1 右圖"
                    maxNumber={1}
                    maxFileSize={8000000}
                    images={sectionOneRightImg}
                    onChangeImages={setSectionOneRightImg}
                  />
                </div>

                {/** SECTION 2 */}
                <FormInput
                  title="章節2 標題"
                  name="section_2_subtitle"
                  example="委託銷售"
                />
                <FormInput
                  title="章節2 內容"
                  half={false}
                  name="section_2_description"
                  example="911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。"
                />
                <div className="flex">
                  <ImageUpload
                    title="章節2 左圖"
                    maxNumber={1}
                    maxFileSize={8000000}
                    images={sectionTwoLeftImg}
                    onChangeImages={setSectionTwoLeftImg}
                  />
                  <ImageUpload
                    title="章節2 右圖"
                    maxNumber={1}
                    maxFileSize={8000000}
                    images={sectionTwoRightImg}
                    onChangeImages={setSectionTwoRightImg}
                  />
                </div>

                <section className="width-100">
                  {characteristicsItems.length > 0 && (
                    <CarouselTopic
                      header={'專屬服務（預覽）'}
                      characteristics={characteristicsItems}
                      carImages={characteristicsImages.map(
                        image => image.data_url,
                      )}
                    />
                  )}
                  <FormProvider {...characteristicFormMethods}>
                    <form
                      className="sub-form-wrapper"
                      onSubmit={handleCharacteristicSubmit(
                        onCharacteristicsSubmit,
                      )}
                    >
                      <div className="characteristics-wrapper">
                        <FormInput
                          title="特色標題"
                          half={false}
                          name="title"
                          example="真皮套件"
                        />
                        <FormInput
                          title="特色敘述"
                          name="description"
                          half={false}
                          textarea={true}
                          example="方向機柱飾板、中央置物盒蓋、遮陽板和中控台皆以高品質真皮皮革製成，並搭配黑色氧化鋁合金鑲嵌裝飾。"
                        />
                        <ImageUpload
                          title="特色圖片"
                          maxNumber={1}
                          maxFileSize={8000000}
                          images={characteristicsImage}
                          onChangeImages={setCharacteristicsImage}
                        />
                        <div className="btn-characteristics-wrapper">
                          <button
                            className="btn-remove"
                            onClick={handleCharacteristicsDeleteAll}
                          >
                            全部刪除
                          </button>
                          <button
                            className="btn-add"
                            onClick={handleCharacteristicSubmit(
                              onCharacteristicsSubmit,
                            )}
                          >
                            新增經典特色
                          </button>
                          <div>
                            {characteristicMessage && (
                              <ErrorMessage message={characteristicMessage} />
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </section>
              </ul>
            </form>
          </FormProvider>
          <section className="sub-form-wrapper">
            {responseMessage && <ErrorMessage message={responseMessage} />}
            <div className="btn-wrapper">
              <button className="btn-confirm" onClick={handleSubmit(onSubmit)}>
                儲存
              </button>
            </div>
          </section>
        </StyledSection>
      )}
    </React.Fragment>
  );
};

export default OffersForm;
