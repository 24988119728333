import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useHttpClient } from 'shared/hooks/http-hook';
import { AuthContext } from 'shared/context/auth-context';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import styled from 'styled-components';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import carIcon from 'assets/icons/car.svg';
import { ReactComponent as CalenderIcon } from 'assets/icons/calender.svg';
import { ReactComponent as MemberIcon } from 'assets/icons/member.svg';
import { ReactComponent as ServiceIcon } from 'assets/icons/service.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as InventoryIcon } from 'assets/icons/inventory.svg';
import { ReactComponent as TopicIcon } from 'assets/icons/topic.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as AboutUsIcon } from 'assets/icons/about-us.svg';
import { ReactComponent as ServicesIcon } from 'assets/icons/services.svg';
import { ReactComponent as BackgroundsIcon } from 'assets/icons/backgrounds.svg';
import {
  InventoryComponentRenderState,
  TopicComponentRenderState,
} from './States';
import HomeForm from '../components/home/HomeForm';
import AboutUsForm from '../components/aboutUs/AboutUsForm';
import InventoryForm from '../components/inventory/InventoryForm';
import Inventory from '../components/inventory/Inventory';
import TopicForm from '../components/topic/TopicForm';
import Topic from '../components/topic/Topic';
import History from '../components/history/History';
import Member from '../components/member/Member';
import Service from '../components/service/Service';
import Contact from '../components/contact/Contact';
import BackgroundForm from '../components/background/BackgroundForm';
import OffersForm from '../components/aboutUs/OffersForm';
import { RefetchContext } from 'shared/context/refetch-context';

const StyledPanel = styled.section`
  display: flex;
  flex-direction: row;
  max-width: 112rem;
  margin: 8.3rem auto auto auto;

  * {
    border: none;
    box-shadow: none;
  }

  .tab-section {
    position: sticky;
    top: 8rem;
    display: none;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 18.6rem;
    height: 38rem;
    margin-left: 1.6rem;
    margin-right: auto;
  }

  .tab-content {
    margin: 0 auto;
  }

  .top-address-panel {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .nav-tabs {
    display: flex;
    flex-direction: column;
    border: none;
    gap: 0.8rem;
    margin-top: 4.6rem;
  }

  .nav-label {
    cursor: pointer;
  }

  .header-wrapper {
    width: 18.6rem;
    height: 9.2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    margin-bottom: 3.2rem;
    > .img-wrapper {
      width: 6.4rem;
      height: 6.4rem;
      background: #1c1c1c;
      border-radius: 4rem;
      display: flex;
      justify-content: center;

      > img {
        width: 3.2rem;
        height: 2.4rem;
        object-fit: contain;
        margin: auto;
        background: none;
      }
    }

    h4 {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin-top: 0.8rem;
    }
  }

  .nav-link {
    padding: 0px 24px;
    gap: 12px;
    width: 186px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 13.8rem;
      height: 2.4rem;
      gap: 1.2rem;
      background: none;

      > :is(img, label, span, svg, text) {
        background: none;
      }

      > label {
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #969696;
      }

      .reservation-count {
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #969696;
      }
    }

    &.active {
      background: #850009;
      border: none;

      .calender,
      .address {
        > path {
          stroke: white;
        }
      }

      > div :is(label, text) {
        color: white;
      }

      .member {
        > path {
          fill: white;
        }

        > polygon {
          fill: white;
        }

        > circle {
          stroke: white;
        }
      }
    }
  }

  .logout-wrapper {
    width: 100%;
    margin-top: 2rem;
    padding-top: 3.2rem;
    border-top: 0.2rem solid #262626;
    > button {
      display: flex;
      width: 13.8rem;
      height: 2.4rem;
      justify-content: flex-start;
      align-items: center;
      gap: 1.5rem;
      padding-left: 0.6rem;
      margin: auto;
      background: none;
      border: none;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #969696;
    }
  }

  .tab-content {
    width: 85.4rem;

    h1 {
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
      padding-bottom: 1.2rem;
    }

    .reservation-list-top-title {
      padding-left: 1.6rem;
    }
  }

  @media only screen and (min-width: 900px) {
    &&& {
      margin-top: 12rem;
    }

    .tab-section {
      display: flex;
    }

    .tab-content {
      .reservation-wrapper {
        border-radius: 16px;
        header {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .reservation-list-top-title {
        padding-left: 0;
      }
    }
  }
`;

const Panel = props => {
  const auth = useContext(AuthContext);
  const {
    refetchTopics,
    refetchHome,
    refetchInventory,
    refetchHistory,
    refetchMember,
    refetchService,
    refetchContact,
    refetchAboutUs,
    refetchOffers,
    refetchBg,
    setRefetchTopics,
    setRefetchHome,
    setRefetchInventory,
    setRefetchHistory,
    setRefetchMember,
    setRefetchService,
    setRefetchContact,
    setRefetchAboutUs,
    setRefetchOffers,
    setRefetchBg,
  } = useContext(RefetchContext);
  const { isLoading } = useHttpClient();
  const [showInventory, setShowInventory] = useState(
    InventoryComponentRenderState.AllInventories,
  );
  const [showTopic, setShowTopic] = useState(
    TopicComponentRenderState.AllTopics,
  );
  const [inventoryItems, setInventoryItems] = useState([]);
  const [currentInventoryItem, setCurrentInventoryItem] = useState({});
  const [currentTopicItem, setCurrentTopicItem] = useState({});
  const [topicItems, setTopicItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isLoggedIn) {
      navigate('/login');
    }
  }, [auth, navigate]);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <StyledPanel>
        <Tab.Container defaultActiveKey="home" activeKey={props.activeKey}>
          <section className="tab-section">
            <div className="header-wrapper">
              <div className="img-wrapper">
                <img src={carIcon} alt="" />
              </div>
              <h4>Admin</h4>
            </div>
            <Nav>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/home"
                  eventKey="home"
                  onClick={() => setRefetchHome(true)}
                >
                  <div>
                    <HomeIcon className="member" />
                    <label className="nav-label">首頁編輯</label>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/inventory"
                  onClick={() => {
                    setRefetchInventory(true);
                  }}
                  eventKey="inventory"
                >
                  <div>
                    <InventoryIcon className="address" />
                    <label className="nav-label">在庫車款</label>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => setRefetchTopics(true)}
                  as={Link}
                  to="/topic"
                  eventKey="topic"
                >
                  <div>
                    <TopicIcon className="member" />
                    <label className="nav-label">專題介紹</label>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    // setShouldRenderHistory(true);
                    setRefetchHistory(true);
                  }}
                  as={Link}
                  to="/reservation"
                  eventKey="reservation"
                >
                  <div>
                    <CalenderIcon className="calender" />
                    <label className="nav-label">預約清單</label>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    // setShouldRenderMember(true);
                    setRefetchMember(true);
                  }}
                  as={Link}
                  to="/member"
                  eventKey="member"
                >
                  <div>
                    <MemberIcon className="member" />
                    <label className="nav-label">會員資料</label>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    // setShouldRenderService(true);
                    setRefetchService(true);
                  }}
                  as={Link}
                  to="/service"
                  eventKey="service"
                >
                  <div>
                    <ServiceIcon className="member" />
                    <label className="nav-label">線上服務</label>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    // setShouldRenderContact(true);
                    setRefetchContact(true);
                  }}
                  as={Link}
                  to="/contact"
                  eventKey="contact"
                >
                  <div>
                    <ContactIcon className="member" />
                    <label className="nav-label">客服招募</label>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    // setShouldRenderAboutUs(true);
                    setRefetchAboutUs(true);
                  }}
                  as={Link}
                  to="/aboutUs"
                  eventKey="aboutUs"
                >
                  <div>
                    <AboutUsIcon className="member" />
                    <label className="nav-label">車咖簡介</label>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    // setShouldRenderOffers(true);
                    setRefetchOffers(true);
                  }}
                  as={Link}
                  to="/offers"
                  eventKey="offers"
                >
                  <div>
                    <ServicesIcon className="member" />
                    <label className="nav-label">服務項目</label>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    // setShouldRenderBg(true);
                    setRefetchBg(true);
                  }}
                  as={Link}
                  to="/bg"
                  eventKey="bg"
                >
                  <div>
                    <BackgroundsIcon className="member" />
                    <label className="nav-label">其他背景圖</label>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="logout-wrapper">
              <button
                type="button"
                onClick={() => {
                  auth.logout();
                  navigate('/login');
                }}
              >
                <LogoutIcon />
                登出
              </button>
            </div>
          </section>
          <Tab.Content>
            <Tab.Pane eventKey="home">
              <h1 className="reservation-list-top-title">首頁編輯</h1>
              {auth.isLoggedIn && (
                <HomeForm refetch={refetchHome} setRefetch={setRefetchHome} />
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="inventory">
              <h1 className="reservation-list-top-title">在庫車款</h1>

              {showInventory ===
                InventoryComponentRenderState.AllInventories && (
                <Inventory
                  inventoryItems={inventoryItems}
                  setInventoryItems={setInventoryItems}
                  setCurrentInventoryItem={setCurrentInventoryItem}
                  showInventory={showInventory}
                  setShowInventory={setShowInventory}
                  refetch={refetchInventory}
                  setRefetch={setRefetchInventory}
                />
              )}

              {showInventory ===
                InventoryComponentRenderState.EditInventory && (
                <InventoryForm
                  buttonName="儲存"
                  showInventory={showInventory}
                  setShowInventory={setShowInventory}
                  setInventoryItems={setInventoryItems}
                  currentInventoryItem={currentInventoryItem}
                  setCurrentInventoryItem={setCurrentInventoryItem}
                  refetch={refetchInventory}
                  setRefetch={setRefetchInventory}
                />
              )}

              {showInventory === InventoryComponentRenderState.NewInventory && (
                <InventoryForm
                  showInventory={showInventory}
                  setShowInventory={setShowInventory}
                  buttonName="新增"
                  setInventoryItems={setInventoryItems}
                  currentInventoryItem={currentInventoryItem}
                  refetch={refetchInventory}
                  setRefetch={setRefetchInventory}
                />
              )}
            </Tab.Pane>

            <Tab.Pane eventKey="topic">
              <h1 className="reservation-list-top-title">專題介紹</h1>
              {showTopic === TopicComponentRenderState.AllTopics && (
                <Topic
                  showTopic={showTopic}
                  setShowTopic={setShowTopic}
                  currentTopicItem={currentTopicItem}
                  setCurrentTopicItem={setCurrentTopicItem}
                  topicItems={topicItems}
                  setTopicItems={setTopicItems}
                  refetch={refetchTopics}
                  setRefetch={setRefetchTopics}
                />
              )}
              {showTopic === TopicComponentRenderState.EditTopic && (
                <TopicForm
                  showTopic={showTopic}
                  setShowTopic={setShowTopic}
                  currentTopicItem={currentTopicItem}
                  setCurrentTopicItem={setCurrentTopicItem}
                  topicItems={topicItems}
                  setTopicItems={setTopicItems}
                  refetch={refetchTopics}
                  setRefetch={setRefetchTopics}
                  buttonName="儲存"
                />
              )}
              {showTopic === TopicComponentRenderState.NewTopic && (
                <TopicForm
                  showTopic={showTopic}
                  setShowTopic={setShowTopic}
                  currentTopicItem={currentTopicItem}
                  setCurrentTopicItem={setCurrentTopicItem}
                  topicItems={topicItems}
                  setTopicItems={setTopicItems}
                  refetchTopics={refetchTopics}
                  setRefetchTopics={setRefetchTopics}
                  refetch={refetchTopics}
                  setRefetch={setRefetchTopics}
                  buttonName="新增"
                />
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="reservation">
              <h1 className="reservation-list-top-title">預約清單</h1>
              <History
                refetch={refetchHistory}
                setRefetch={setRefetchHistory}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="member">
              <h1 className="reservation-list-top-title">會員資料</h1>
              <Member refetch={refetchMember} setRefetch={setRefetchMember} />
            </Tab.Pane>
            <Tab.Pane eventKey="service">
              <h1 className="reservation-list-top-title">線上服務</h1>
              <Service
                refetch={refetchService}
                setRefetch={setRefetchService}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="contact">
              <h1 className="reservation-list-top-title">客服招募</h1>
              <Contact
                refetch={refetchContact}
                setRefetch={setRefetchContact}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="aboutUs">
              <h1 className="reservation-list-top-title">車咖簡介</h1>
              <AboutUsForm
                refetch={refetchAboutUs}
                setRefetch={setRefetchAboutUs}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="offers">
              <h1 className="reservation-list-top-title">服務項目</h1>
              <OffersForm
                refetch={refetchOffers}
                setRefetch={setRefetchOffers}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="bg">
              <h1 className="reservation-list-top-title">其他背景圖</h1>
              <BackgroundForm refetch={refetchBg} setRefetch={setRefetchBg} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </StyledPanel>
    </React.Fragment>
  );
};

export default React.memo(Panel);
