import React, { useEffect, useState } from 'react';
import { Route, useLocation, Navigate, Routes } from 'react-router-dom';
import Panel from 'pages/Panel';
import Navbar from 'pages/NavBar';
import Login from 'pages/Login';
// import Signup from 'pages/Signup';

import './index.css';
import { AuthContext } from './shared/context/auth-context';
import {
  RefetchContextProvider,
  RefetchContext,
} from 'shared/context/refetch-context';
import { useAuth } from './shared/hooks/auth-hook';
import { useHttpClient } from 'shared/hooks/http-hook';

const Wrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

function Layout({ children }) {
  const { token, login, logout, userId } = useAuth();

  return (
    <Wrapper>
      <RefetchContextProvider>
        <AuthContext.Provider
          value={{
            isLoggedIn: !!token,
            token: token,
            userId: userId,
            login: login,
            logout: logout,
          }}
        >
          <Navbar />
          {children}
          {/* <Footer /> */}
        </AuthContext.Provider>
      </RefetchContextProvider>
    </Wrapper>
  );
}

export function RouterOutlet() {
  return (
    <Layout>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* TODO: Disable signup page in production! */}
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="home" element={<Panel activeKey="home" />} />
        <Route path="inventory" element={<Panel activeKey="inventory" />} />
        <Route path="topic" element={<Panel activeKey="topic" />} />
        <Route path="reservation" element={<Panel activeKey="reservation" />} />
        <Route path="member" element={<Panel activeKey="member" />} />
        <Route path="service" element={<Panel activeKey="service" />} />
        <Route path="contact" element={<Panel activeKey="contact" />} />
        <Route path="aboutUs" element={<Panel activeKey="aboutUs" />} />
        <Route path="bg" element={<Panel activeKey="bg" />} />
        <Route path="offers" element={<Panel activeKey="offers" />} />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </Layout>
  );
}
