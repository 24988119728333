import styled from 'styled-components';
import alertIcon from 'assets/icons/alertIcon.svg';
import EmptyTopic from './EmptyTopic';
import TopicCardPanel from './TopicCardPanel';
import React, { useState, useEffect, useContext } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { TopicComponentRenderState } from 'pages/States';
import { useHttpClient } from 'shared/hooks/http-hook';
import { AuthContext } from 'shared/context/auth-context';

const TopicWrapper = styled.section`
  .top-title-exclamation {
    display: flex;
    align-items: flex-start;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #969696;
    gap: 0.8rem;
    margin-bottom: 4.5rem;
  }

  .top-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2.8rem;
    justify-content: space-between;
  }

  .btn-add-new-address-top {
    gap: 10px;
    width: 80px;
    height: 40px;
    border: 2px solid #ffffff;
    border-radius: 30px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  @media only screen and (min-width: 900px) {
    .top-title-exclamation {
      align-items: center;
    }

    .btn-add-new-address-top {
      margin-right: 1.6rem;
    }
  }
`;

const StyledReactSearchAutocomplete = styled.div`
  margin: 3rem;
  * {
    background-color: transparent;
  }
  .ellipsis {
    color: #ffffff;
    span {
      color: #ffffff;
    }
  }

  .wrapper {
    /* display: none; */
    .line {
      display: none;
    }
    div:nth-child(2) {
      display: none;
    }
  }
`;

const Topic = ({
  showTopic,
  setShowTopic,
  currentTopicItem,
  setCurrentTopicItem,
  topicItems,
  setTopicItems,
  refetch,
  setRefetch,
}) => {
  const [searchedTopicItems, setSearchedTopicItems] = useState(topicItems);
  const { setIsLoading, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const fetchTopicItems = async () => {
    try {
      if (auth.isLoggedIn) {
        setIsLoading(true);
        if (
          showTopic === TopicComponentRenderState.EditTopic ||
          showTopic === TopicComponentRenderState.AllTopics
        ) {
          try {
            const responseData = await sendRequest(
              process.env.REACT_APP_BACKEND_URL + '/topic',
              'GET',
              null,
              { Authorization: 'Bearer ' + auth.token },
            );
            setTopicItems(responseData);
          } catch (err) {
            console.log('err :>>', err);
            setIsLoading(false);
          }
        }
      }
    } catch (err) {
      console.log('err :>>>', err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTopicItemsWrapper = async () => {
    await fetchTopicItems();
  };

  useEffect(() => {
    if (!refetch) return;
    fetchTopicItemsWrapper();
    setRefetch(false);
  }, [refetch]);

  useEffect(() => {
    setSearchedTopicItems(topicItems);
  }, [topicItems]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log("onSearch", string, results);
    if (string === '') {
      setSearchedTopicItems(topicItems);
    } else if (results.length > 0) {
      setSearchedTopicItems(results);
    }
  };
  // console.log("topicItems", topicItems);
  // console.log("searchedTopicItems", searchedTopicItems);

  const handleOnHover = result => {
    // the item hovered
    // console.log(result);
  };

  const handleOnSelect = item => {
    // the item selected
    // console.log("onSelect", item);
    // console.log(item);
  };

  const handleOnFocus = () => {
    // console.log("Focused");
  };

  const formatResult = item => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>
          {item.title}
        </span>
      </>
    );
  };

  return (
    <React.Fragment>
      <TopicWrapper>
        <div className="top-title-exclamation">
          <img className="alert-icon" src={alertIcon} alt=""></img>
          下列專題會即時更新在網站上,您可以在此修改專題
        </div>
        <StyledReactSearchAutocomplete>
          <ReactSearchAutocomplete
            items={topicItems}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            maxResults={topicItems.length}
            formatResult={formatResult}
            showNoResultsText="無搜尋結果"
            placeholder="輸入關鍵字"
            resultStringKeyName="topic_title"
            maxLength={30}
            fuseOptions={{
              keys: [
                'bottom_car_description',
                'bottom_car_title',
                'brand',
                'car_title',
                'left_car_description',
                'left_car_title',
                'right_car_description',
                'right_car_title',
                'topic_title',
              ],
            }}
            styling={{
              backgroundColor: '#000000',
              borderRadius: '2.4rem',
              border: '2px solid #969696',
              fontFamily: 'Albert Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '1.6rem',
              color: '#ffffff',
              iconColor: '#ffffff',
              lineHeight: '1.8rem',
              hoverBackgroundColor: '#1c1c1c',
              zIndex: '998',
              lineColor: '#969696',
              placeholderColor: '#969696',
            }}
          />
        </StyledReactSearchAutocomplete>

        <div className="top-title-wrapper">
          <h1 className="top-title-text">目前在網站上的專題介紹</h1>
          <button
            onClick={() => setShowTopic(TopicComponentRenderState.NewTopic)}
            className="btn-add-new-address-top"
          >
            新增
          </button>
        </div>
        {!topicItems.length && <EmptyTopic label="尚未設定專題" />}
        {searchedTopicItems.length && (
          <TopicCardPanel
            searchedTopicItems={searchedTopicItems}
            showTopic={showTopic}
            setShowTopic={setShowTopic}
            currentTopicItem={currentTopicItem}
            setCurrentTopicItem={setCurrentTopicItem}
            topicItems={topicItems}
            setTopicItems={setTopicItems}
            setRefetch={setRefetch}
          />
        )}
      </TopicWrapper>
    </React.Fragment>
  );
};

export default Topic;
