import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Navbar as BSNavBar, NavDropdown } from 'react-bootstrap';
import carZecureIcon from 'assets/icons/car-zecure.svg';
import menuIcon from 'assets/icons/menu.svg';
import plusIcon from 'assets/icons/plus.svg';
import minusIcon from 'assets/icons/minus.svg';
import loginIcon from 'assets/icons/login.svg';
import loginIconRed from 'assets/icons/login-red.svg';
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import styled from 'styled-components';
import { AuthContext } from 'shared/context/auth-context';
import { RefetchContext } from 'shared/context/refetch-context';

import 'bootstrap/dist/css/bootstrap.min.css';

const StyledBSNavBar = styled(BSNavBar)`
  width: 100%;
  height: 5.9rem;
  padding: 0;
  background-color: black;

  .navbar-collapse {
    padding-left: 1.85rem;
    padding-right: 1.85rem;
  }

  .navbar-nav {
    background-color: transparent;
  }

  .navbar-brand {
    width: 12.4rem;
    height: 2.4rem;
    padding: 0;
    margin: 0;
  }

  .d-inline-block {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .nav-link,
  .dropdown-item {
    color: white;
    height: 4.8rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid #262626;
    background-color: transparent;
    &.active {
      color: white;
    }
    &:hover {
      border-bottom: 2px solid #850009;
    }
    &:focus {
      border-bottom: 2px solid #cd0715;
    }
  }

  .nav-item {
    background-color: transparent;
  }

  .dropdown-item {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #969696;
    border-bottom: none;
    padding: 1.6rem;
    &:hover {
      background: #850009;
      border-radius: 8px;
      color: white;
    }
  }

  .navbar-toggler {
    padding: 2.2rem;
    background: none;
    border: none;
    &:focus {
      box-shadow: 0 0 0 0;
    }
  }

  .navbar-toggler-icon {
  }

  .hamburger {
    width: 1.6rem;
    height: 1.6rem;
    background-image: url(${menuIcon});
    background-size: contain;
  }

  .dropdown-toggle.show.nav-link {
    color: white;
    border-bottom: 2px solid #850009;
    background-color: transparent;
    &:after {
      border: none;
      margin-left: auto;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url(${minusIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    .right-wrapper {
      display: none;
      background-color: transparent;
    }
  }

  .dropdown-toggle.nav-link {
    color: white;
    &:after {
      border: none;
      width: 2.4rem;
      height: 2.4rem;
      margin-left: auto;
      background-image: url(${plusIcon});
    }
  }

  .dropdown-menu.show {
    background: none;
  }

  .right-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
    height: 100%;
    background: transparent;
    gap: 0.8rem;
    margin-right: 1.2rem;

    .login-register-button {
      width: 7rem;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      border: none;
      text-decoration: none;
      background-color: transparent;
      :hover {
        color: #cd0715;
      }
    }

    .login-button {
      display: flex;
      width: 1.806rem;
      height: 2.168rem;
      background-image: url(${loginIcon});
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      background-color: transparent;
      :hover {
        background-image: url(${loginIconRed});
      }
    }
  }

  .search-bar-wrapper {
    width: 36rem;
    height: 4.8rem;
    background: none;
    /* border: none; */
    margin-right: 1.6rem;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    .wrapper {
      /* width: 36rem; */
      height: 4.8rem;

      background: none;
      /* padding: 1.6rem; */
      > div {
        display: border-box;
        background: none;
        /* margin-top: 1.6rem; */
        /* margin-bottom: 1.6rem; */
        /* padding-top: 0;
        padding-bottom: 0;
        */
        /* padding: 1.6rem; */
        /* height: 3rem; */

        .search-icon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 2.4rem;
        }
      }
    }
  }

  @media only screen and (min-width: 400px) {
    .right-wrapper {
      gap: 2.688rem;
    }
  }

  @media only screen and (min-width: 992px) {
    display: flex;
    justify-content: space-around;
    background-color: transparent;

    .navbar-collapse {
      margin: 0;
      flex-grow: 0;
      flex-shrink: 1;
      background-color: transparent;
    }

    .navbar-brand,
    .d-inline-block {
      width: 16rem;
      height: 4.345rem;
      object-fit: contain;
      background: #cd0715;
    }

    .nav-link {
      width: 12rem;
      height: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: none;
    }

    .highlight.nav-link {
      border-bottom: 2px solid #cd0715;
    }

    .dropdown-toggle.show.nav-link {
      &:after {
        margin-left: 0.8rem;
        width: 1.4rem;
        height: 1.4rem;
        background-image: url(${arrowDownIcon});
      }
    }

    .dropdown-toggle.nav-link {
      &:after {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 0.8rem;
        background-image: url(${arrowDownIcon});
      }
    }
    .dropdown-menu.show {
      width: 100%;
      margin-top: 0.8rem;
      background: #1c1c1c;
      border-radius: 0.8rem;
      align-items: center;
    }
    .dropdown-item {
      &:hover {
        border-radius: 0;
        color: white;
      }
    }

    .right-wrapper {
      justify-self: end;
      order: 1;
    }

    .login-button.remove-hover-highlight {
      :hover {
        background-image: url(${loginIcon});
      }
    }
  }

  @media only screen and (min-width: 1100px) {
    display: flex;
    justify-content: center;
    padding-left: 5.5rem;
    padding-right: 3.5rem;
  }

  @media only screen and (min-width: 1200px) {
    justify-content: space-between;
    padding-left: 5.5rem;
    padding-right: 3.5rem;
    .right-wrapper {
      .login-button {
        display: flex;
      }
      .search-btn {
        display: flex;
      }
    }
  }
`;

const Navbar = () => {
  const auth = useContext(AuthContext);
  // console.log("Navbar auth", auth);
  const location = useLocation();
  // console.log(location);
  const {
    setRefetchTopics,
    setRefetchHome,
    setRefetchInventory,
    setRefetchHistory,
    setRefetchMember,
    setRefetchService,
    setRefetchContact,
    setRefetchAboutUs,
    setRefetchOffers,
    setRefetchBg,
  } = useContext(RefetchContext);
  return (
    <>
      {auth.isLoggedIn && (
        <StyledBSNavBar collapseOnSelect sticky="top" expand="lg">
          <StyledBSNavBar.Toggle aria-controls="responsive-navbar-nav">
            <div className="hamburger"></div>
          </StyledBSNavBar.Toggle>
          <StyledBSNavBar.Brand as={Link} to="/home" href="#">
            <img src={carZecureIcon} alt="" className="d-inline-block" />
          </StyledBSNavBar.Brand>
          <div className="right-wrapper"></div>
          <StyledBSNavBar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link
                className={location.pathname === '/home' ? 'highlight' : ''}
                as={Link}
                onClick={() => setRefetchHome(true)}
                to="home"
                href="#"
              >
                首頁編輯
              </Nav.Link>
              <Nav.Link
                className={
                  location.pathname === '/inventory' ? 'highlight' : ''
                }
                as={Link}
                onClick={() => setRefetchInventory(true)}
                to="inventory"
                href="#"
              >
                在庫車款
              </Nav.Link>
              <Nav.Link
                className={location.pathname === '/topic' ? 'highlight' : ''}
                as={Link}
                onClick={() => setRefetchTopics(true)}
                to="topic"
                href="#"
              >
                專題介紹
              </Nav.Link>
              <Nav.Link
                className={
                  location.pathname === '/reservation' ? 'highlight' : ''
                }
                as={Link}
                onClick={() => setRefetchHistory(true)}
                to="reservation"
                href="#"
              >
                預約清單
              </Nav.Link>
              <Nav.Link
                className={location.pathname === '/member' ? 'highlight' : ''}
                as={Link}
                onClick={() => setRefetchMember(true)}
                to="member"
                href="#"
              >
                會員資料
              </Nav.Link>
              <NavDropdown title="表單內容">
                <NavDropdown.Item
                  as={Link}
                  to="service"
                  onClick={() => setRefetchService(true)}
                  href="#"
                >
                  線上服務
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  onClick={() => setRefetchContact(true)}
                  to="contact"
                  href="#"
                >
                  客服招募
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="aboutUs"
                  onClick={() => setRefetchAboutUs(true)}
                  href="#"
                >
                  車咖簡介
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  onClick={() => setRefetchOffers(true)}
                  to="offers"
                  href="#"
                >
                  服務項目
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  onClick={() => setRefetchBg(true)}
                  to="bg"
                  href="#"
                >
                  其他背景圖
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} onClick={auth.logout} to="login" href="#">
                登出
              </Nav.Link>
            </Nav>
          </StyledBSNavBar.Collapse>
        </StyledBSNavBar>
      )}
    </>
  );
};

export default Navbar;
