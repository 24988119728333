import styled from 'styled-components';
import topicEmptyIcon from 'assets/images/item-empty.svg';

const EmptyTopicWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44.6rem;
    gap: 1.5rem;
    background: #1c1c1c;
    border-radius: 16px;
  }

  .empty-topic-icon {
    width: 4.2rem;
    height: 4.2rem;
    background: none;
  }

  .empty-topic-label {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background: none;
  }
`;

const EmptyTopic = ({ label }) => {
  return (
    <EmptyTopicWrapper>
      <img className="empty-topic-icon" src={topicEmptyIcon} alt="" />
      <h3 className="empty-topic-label">{label}</h3>
    </EmptyTopicWrapper>
  );
};

export default EmptyTopic;
