import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import ErrorMessage from 'shared/components/FormElements/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import FormInput from 'shared/components/FormElements/FormInput';
import ImageUpload from 'shared/components/FormElements/ImageUpload';
import VideoUpload from 'shared/components/FormElements/VideoUpload';
import { AuthContext } from 'shared/context/auth-context';
import { urlToObject } from 'shared/utils/urlToObject';

import { TopicComponentRenderState } from 'pages/States';
import CarouselTopic from './CarouselTopic';

const StyledTopic = styled.section`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .sub-form-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    width: 100%;
    max-width: 640px;
    background: rgba(1, 0, 1, 0.4);
    backdrop-filter: blur(4px);
    border-radius: 1.6rem;
    margin: auto;
  }

  .sub-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.4rem;
    padding: 0;
    background: none;
  }

  .form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background: none;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0;
    margin: 0;
    background: none;
  }

  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    background: none;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .more-description {
    padding: 1.2rem 1.6rem;
    height: 9.6rem;
    resize: none;
  }

  .upload-file {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: 0.2rem solid #ffffff;
    border-radius: 3rem;
    background: none;
    padding: 1rem 2.4rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    justify-self: end;
    flex: 0 0 auto;
  }

  .uploaded-file {
    height: fit-content;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    flex: 0 1 auto;
    word-break: break-all;
    text-align: right;
    vertical-align: middle;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    padding-top: 5.6rem;
    padding-bottom: 3.2rem;
  }

  .btn-back,
  .btn-confirm {
    width: 15.6rem;
    height: 4.8rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .btn-confirm {
    background: #cd0715;
  }

  .characteristics-wrapper {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.6rem;
    /* background: #1c1c1c; */
    border-radius: 16px;
    border: 0.3rem solid #969696;

    .characteristics-image-upload-wrapper {
      background: #1c1c1c;
    }

    .btn-characteristics-wrapper {
      display: flex;
      gap: 1.6rem;
      align-self: end;
      .btn-add,
      .btn-remove {
        align-self: flex-end;
        width: 15.6rem;
        height: 4.8rem;
        border: 2px solid #ffffff;
        border-radius: 24px;
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        background-color: #cd0715;
      }
      .btn-remove {
        background-color: #1c1c1c;
      }
    }
  }

  @media only screen and (min-width: 720px) {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0rem;
    border-bottom: 2px solid #262626;

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3.2rem;
    }

    .half-element {
      width: 47%;
    }

    .btn-wrapper {
      padding-top: 4rem;
    }
  }
`;

const TopicForm = ({
  buttonName,
  showTopic,
  setShowTopic,
  currentTopicItem,
  setCurrentTopicItem,
  topicItems,
  setTopicItems,
  setRefetch,
  refetch,
}) => {
  // Create the states/setState for the above fields
  const [video, setVideo] = useState('');
  const [carouselImage, setCarouselImage] = useState([]);
  const [carImage, setCarImage] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const [overlayImage, setOverlayImage] = useState([]);
  const [rightCarImage, setRightCarImage] = useState([]);
  const [leftCarImage, setLeftCarImage] = useState([]);
  const [bottomCarImage, setBottomCarImage] = useState([]);
  const [characteristicsImage, setCharacteristicsImage] = useState([]);
  const [characteristicsItems, setCharacteristicsItems] = useState([]);
  const [characteristicMessage, setCharacteristicMessage] = useState('');
  const [characteristicsImages, setCharacteristicsImages] = useState([]);
  const charLength = 1023;
  // Setup the form validation
  const formSchema = yup.object().shape({
    brand: yup
      .string()
      .required('廠牌為必填')
      .max(charLength, `廠牌最長為${charLength}字`),
    car_title: yup
      .string()
      .required('車名為必填')
      .max(charLength, `車名最長為${charLength}字`),
    topic_title: yup
      .string()
      .required('專題標題為必填')
      .max(charLength, `專題標題最長為${charLength}字`),
    right_car_title: yup
      .string()
      .required('右圖標題為必填')
      .max(charLength, `右圖標題最長為${charLength}字`),
    right_car_description: yup
      .string()
      .required('右圖描述為必填')
      .max(charLength, `右圖描述最長為${charLength}字`),
    left_car_title: yup
      .string()
      .required('左圖標題為必填')
      .max(charLength, `左圖標題最長為${charLength}字`),
    left_car_description: yup
      .string()
      .required('左圖描述為必填')
      .max(charLength, `左圖描述最長為${charLength}字`),
    bottom_car_title: yup
      .string()
      .required('下圖標題為必填')
      .max(charLength, `下圖標題最長為${charLength}字`),
    bottom_car_description: yup
      .string()
      .required('下圖描述為必填')
      .max(charLength, `下圖描述最長為${charLength}字`),
  });

  const formMethods = useForm({ resolver: yupResolver(formSchema) });
  const {
    setValue,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = formMethods;

  const characteristicFormSchema = yup.object().shape({
    title: yup
      .string()
      .required('特色標題為必填')
      .max(charLength, `特色標題最長為${charLength}字`),
    description: yup
      .string()
      .required('特色敘述為必填')
      .max(charLength, `特色敘述最長為${charLength}字`),
  });

  const characteristicFormMethods = useForm({
    resolver: yupResolver(characteristicFormSchema),
  });
  const {
    reset: characteristicReset,
    handleSubmit: handleCharacteristicSubmit,
  } = characteristicFormMethods;

  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const [isCalculatingDuration, setIsCalculatingDuration] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const auth = useContext(AuthContext);

  const setDefaultItem = async currentTopicItem => {
    if (showTopic === TopicComponentRenderState.EditTopic) {
      setIsCalculatingDuration(true);
      for (let name in currentTopicItem) {
        setValue(name, currentTopicItem[name]);
      }
      const urlPrefix = `${process.env.REACT_APP_DATA_URL}/uploads/topic/`;
      const [
        video,
        carouselImageData,
        carImageData,
        bannerImageData,
        overlayImageData,
        rightCarImageData,
        leftCarImageData,
        bottomCarImageData,
        characteristicsImages,
      ] = await Promise.all([
        await urlToObject(urlPrefix + currentTopicItem.video, 'video'),
        await urlToObject(urlPrefix + currentTopicItem.carousel_image),
        await urlToObject(urlPrefix + currentTopicItem.car_image),
        await urlToObject(urlPrefix + currentTopicItem.topic_banner_image),
        await urlToObject(urlPrefix + currentTopicItem.video_overlay_image),
        await urlToObject(urlPrefix + currentTopicItem.right_car_image),
        await urlToObject(urlPrefix + currentTopicItem.left_car_image),
        await urlToObject(urlPrefix + currentTopicItem.bottom_car_image),
        await Promise.all(
          currentTopicItem.characteristics_images.map(async item => {
            const file = await urlToObject(urlPrefix + item);
            return {
              data_url: urlPrefix + item,
              file,
            };
          }),
        ),
      ]);

      setVideo(video);
      setCarouselImage([
        {
          data_url: urlPrefix + currentTopicItem.carousel_image,
          file: carouselImageData,
        },
      ]);
      setCarImage([
        {
          data_url: urlPrefix + currentTopicItem.car_image,
          file: carImageData,
        },
      ]);
      setBannerImage([
        {
          data_url: urlPrefix + currentTopicItem.topic_banner_image,
          file: bannerImageData,
        },
      ]);
      setOverlayImage([
        {
          data_url: urlPrefix + currentTopicItem.video_overlay_image,
          file: overlayImageData,
        },
      ]);
      setRightCarImage([
        {
          data_url: urlPrefix + currentTopicItem.right_car_image,
          file: rightCarImageData,
        },
      ]);
      setLeftCarImage([
        {
          data_url: urlPrefix + currentTopicItem.left_car_image,
          file: leftCarImageData,
        },
      ]);
      setBottomCarImage([
        {
          data_url: urlPrefix + currentTopicItem.bottom_car_image,
          file: bottomCarImageData,
        },
      ]);
      setCharacteristicsImages(characteristicsImages);
      // Combine currentTopicItem.characteristics_titles and set the characteristicsItems
      setCharacteristicsItems(
        currentTopicItem.characteristics_titles.map((item, index) => {
          return {
            title: item,
            description: currentTopicItem.characteristics_descriptions[index],
          };
        }),
      );
      setIsCalculatingDuration(false);
    }
  };

  const fetchTopicItems = async () => {
    try {
      if (auth.isLoggedIn) {
        if (
          showTopic === TopicComponentRenderState.EditTopic ||
          showTopic === TopicComponentRenderState.AllTopics
        ) {
          try {
            setIsLoading(true);
            const responseData = await sendRequest(
              process.env.REACT_APP_BACKEND_URL + '/topic',
              'GET',
              null,
              { Authorization: 'Bearer ' + auth.token },
            );
            setTopicItems(responseData);
            const currentItemId = localStorage.getItem('topicItemId');
            const currentItem = responseData.find(
              item => item.id === Number(currentItemId),
            );
            await setDefaultItem(currentItem);
          } catch (err) {
            console.log('err :>>', err);
            setIsLoading(false);
          }
        }
      }
    } catch (err) {
      console.log('err :>>>', err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTopicItemsWrapper = async () => {
    await fetchTopicItems();
  };

  useEffect(() => {
    if (!refetch) return;
    fetchTopicItemsWrapper();
    setRefetch(false);
  }, [refetch]);

  useEffect(() => {
    if (isDirty) {
      setResponseMessage('');
    }
  }, [isDirty]);

  const getVideoDuration = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = error => reject(error);
    });

  const onSubmit = async data => {
    console.log('Sending data...', data);
    try {
      const formData = new FormData();
      if (buttonName === '儲存') {
        formData.append('id', data.id);
      }
      setIsCalculatingDuration(true);
      const duration = await getVideoDuration(video);
      setIsCalculatingDuration(false);

      const videoLength = new Date(duration * 1000)
        .toISOString()
        .substring(14, 19);
      formData.append('video_length', videoLength);
      formData.append('brand', data.brand);
      formData.append('car_title', data.car_title);
      formData.append('topic_title', data.topic_title);
      formData.append('right_car_title', data.right_car_title);
      formData.append('right_car_description', data.right_car_description);
      formData.append('left_car_title', data.left_car_title);
      formData.append('left_car_description', data.left_car_description);
      formData.append('bottom_car_title', data.bottom_car_title);
      formData.append('bottom_car_description', data.bottom_car_description);
      formData.append(
        'characteristics_titles',
        JSON.stringify(characteristicsItems.map(item => item.title)),
      );
      formData.append(
        'characteristics_descriptions',
        JSON.stringify(characteristicsItems.map(item => item.description)),
      );
      // Note: the order of the images is important
      formData.append('materials', carouselImage[0].file);
      formData.append('materials', carImage[0].file);
      formData.append('materials', bannerImage[0].file);
      formData.append('materials', overlayImage[0].file);
      formData.append('materials', rightCarImage[0].file);
      formData.append('materials', leftCarImage[0].file);
      formData.append('materials', bottomCarImage[0].file);
      formData.append('materials', video);
      for (const image of characteristicsImages) {
        formData.append('materials', image.file);
      }
      const responseData = await sendRequest(
        buttonName === '新增'
          ? process.env.REACT_APP_BACKEND_URL + '/admin/topic/new'
          : process.env.REACT_APP_BACKEND_URL + '/admin/topic/update',
        'POST',
        formData,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );

      // Reset the form
      if (buttonName === '新增') {
        reset({
          brand: '',
          car_title: '',
          topic_title: '',
          right_car_title: '',
          right_car_description: '',
          left_car_title: '',
          left_car_description: '',
          bottom_car_title: '',
          bottom_car_description: '',
          characteristics_titles: [],
          characteristics_descriptions: [],
        });

        // Reset the states
        setVideo(null);
        setCarouselImage([]);
        setCarImage([]);
        setBannerImage([]);
        setOverlayImage([]);
        setRightCarImage([]);
        setLeftCarImage([]);
        setBottomCarImage([]);
        setCharacteristicsImages([]);
      }
      // Set the response message
      setRefetch(true);
      setResponseMessage(responseData.message);
    } catch (err) {
      console.log(err);
      setRefetch(false);
      setResponseMessage(
        '請確認所有必填欄位皆已填寫，圖片上傳正確，且格式正確',
      );
    }
    document.documentElement.scrollTo(0, document.body.scrollHeight);
  };

  const onCharacteristicSubmit = async data => {
    if (characteristicsImage.length === 0) {
      setCharacteristicMessage('請上傳圖片');
      return;
    }
    // Append the new characteristic to the existing ones
    setCharacteristicsItems(previousItems => {
      if (previousItems.length === 0) {
        // If there is no characteristic yet
        return [{ title: data.title, description: data.description }];
      } else {
        // If there are characteristics already
        return [
          ...previousItems,
          { title: data.title, description: data.description },
        ];
      }
    });
    setCharacteristicsImages(previousImages => {
      if (previousImages.length === 0) {
        // If there is no characteristic yet
        return [characteristicsImage[0]];
      } else {
        // If there are characteristics already
        return [...previousImages, characteristicsImage[0]];
      }
    });
    setCharacteristicMessage('');
    characteristicReset({
      title: '',
      description: '',
    });
    setCharacteristicsImage([]);
  };

  const handleCharacteristicDeleteAll = e => {
    e.preventDefault();
    setCharacteristicMessage('');
    characteristicReset({
      title: '',
      description: '',
    });
    setCharacteristicsItems([]);
    setCharacteristicsImages([]);
  };

  return (
    <>
      {(isLoading || isCalculatingDuration) && <LoadingSpinner asOverlay />}
      {!isLoading && !isCalculatingDuration && (
        <StyledTopic>
          <FormProvider {...formMethods}>
            <form
              className="sub-form-wrapper"
              onSubmit={handleSubmit(onSubmit)}
            >
              <ul className="sub-form">
                <FormInput title="廠牌" name="brand" example="Porsche" />
                <FormInput
                  title="車名"
                  name="car_title"
                  example="911 Carrera 4 GTS"
                />
                <FormInput
                  title="專題標題"
                  half={false}
                  name="topic_title"
                  example="Design 50 週年"
                />
                <FormInput
                  title="右圖標題"
                  name="right_car_title"
                  half={false}
                  textarea={false}
                  example="永恆的設計，現代的詮釋"
                />
                <FormInput
                  title="右圖描述"
                  name="right_car_description"
                  half={false}
                  textarea={true}
                  example="911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線
                （Porsche Flyline）」使得 911 Carrera 外型顯而易見，
                自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。"
                />
                <FormInput
                  title="左圖標題"
                  name="left_car_title"
                  half={false}
                  textarea={false}
                  example="為永無止盡的道路而生"
                />
                <FormInput
                  title="左圖描述"
                  name="left_car_description"
                  half={false}
                  textarea={true}
                  example="每輛 911 都有相同的目標：駕馭。
                並盡可能運動化。這就是為什麼引擎、變速箱、底盤、
                煞車和所有控制系統的設計目標相同：最高性能。"
                />
                <FormInput
                  title="下圖標題"
                  name="bottom_car_title"
                  half={false}
                  textarea={false}
                  example="源自 1972 年，舉手之間盡顯跑車精隨"
                />

                <FormInput
                  title="下圖描述"
                  name="bottom_car_description"
                  half={false}
                  textarea={true}
                  example="這款獨一無二的腕錶，不僅透過設計
                         、材質來呼應與車款間的連結， 更承襲 F. A. Porsche 教授的極簡清晰的設計語彙
                         ，來證明其 Porsche Design 的共同根源。"
                />
              </ul>
            </form>
          </FormProvider>
          <section>
            {characteristicsItems.length > 0 && (
              <CarouselTopic
                characteristics={characteristicsItems}
                carImages={characteristicsImages.map(image => image.data_url)}
              />
            )}
            <FormProvider {...characteristicFormMethods}>
              <form
                className="sub-form-wrapper"
                onSubmit={handleCharacteristicSubmit(onCharacteristicSubmit)}
              >
                <div className="characteristics-wrapper">
                  <FormInput
                    title="特色標題"
                    half={false}
                    name="title"
                    example="真皮套件"
                  />
                  <FormInput
                    title="特色敘述"
                    name="description"
                    half={false}
                    textarea={true}
                    example="方向機柱飾板、中央置物盒蓋、遮陽板和中控台皆以高品質真皮皮革製成，並搭配黑色氧化鋁合金鑲嵌裝飾。"
                  />
                  <ImageUpload
                    title="特色圖片"
                    maxNumber={1}
                    maxFileSize={8000000}
                    images={characteristicsImage}
                    onChangeImages={setCharacteristicsImage}
                  />
                  <div className="btn-characteristics-wrapper">
                    <button
                      className="btn-remove"
                      onClick={handleCharacteristicDeleteAll}
                    >
                      全部刪除
                    </button>
                    <button
                      className="btn-add"
                      onClick={handleCharacteristicSubmit(
                        onCharacteristicSubmit,
                      )}
                    >
                      新增經典特色
                    </button>
                    <div>
                      {characteristicMessage && (
                        <ErrorMessage message={characteristicMessage} />
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </section>
          <section className="sub-form-wrapper">
            <div className="sub-form">
              <VideoUpload video={video} onChangeVideo={setVideo} />
              <ImageUpload
                title="輪播用圖片"
                maxNumber={1}
                maxFileSize={8000000}
                images={carouselImage}
                onChangeImages={setCarouselImage}
              />

              <ImageUpload
                title="車輛圖片"
                maxNumber={1}
                maxFileSize={8000000}
                images={carImage}
                onChangeImages={setCarImage}
              />
              <ImageUpload
                title="專題報導圖片"
                maxNumber={1}
                maxFileSize={8000000}
                images={bannerImage}
                onChangeImages={setBannerImage}
              />
              <ImageUpload
                title="影片靜止圖片"
                maxNumber={1}
                maxFileSize={8000000}
                images={overlayImage}
                onChangeImages={setOverlayImage}
              />
              <ImageUpload
                title="右圖"
                maxNumber={1}
                maxFileSize={8000000}
                images={rightCarImage}
                onChangeImages={setRightCarImage}
              />
              <ImageUpload
                title="左圖"
                maxNumber={1}
                maxFileSize={8000000}
                images={leftCarImage}
                onChangeImages={setLeftCarImage}
              />
              <ImageUpload
                title="下圖"
                maxNumber={1}
                maxFileSize={8000000}
                images={bottomCarImage}
                onChangeImages={setBottomCarImage}
              />
            </div>
            {responseMessage && <ErrorMessage message={responseMessage} />}
            <div className="btn-wrapper">
              <button
                onClick={e => {
                  e.preventDefault();
                  setShowTopic(TopicComponentRenderState.AllTopics);
                  setRefetch(true);
                }}
                className="btn-back"
              >
                返回
              </button>
              <button className="btn-confirm" onClick={handleSubmit(onSubmit)}>
                {buttonName}
              </button>
            </div>
          </section>
        </StyledTopic>
      )}
    </>
  );
};

export default TopicForm;
