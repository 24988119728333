import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import ErrorMessage from 'shared/components/FormElements/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import { AuthContext } from 'shared/context/auth-context';
import FormInput from 'shared/components/FormElements/FormInput';
import ImageUpload from 'shared/components/FormElements/ImageUpload';
import { urlToObject } from 'shared/utils/urlToObject';

const StyledSection = styled.section`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .sub-form-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    width: 100%;
    max-width: 640px;
    background: rgba(1, 0, 1, 0.4);
    backdrop-filter: blur(4px);
    border-radius: 1.6rem;
    margin: auto;
  }

  .sub-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.4rem;
    padding: 0;
    background: none;
  }

  .flex {
    display: flex;
  }

  .form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background: none;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0;
    margin: 0;
    background: none;
  }

  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    background: none;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .more-description {
    padding: 1.2rem 1.6rem;
    height: 9.6rem;
    resize: none;
  }

  .upload-file {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: 0.2rem solid #ffffff;
    border-radius: 3rem;
    background: none;
    padding: 1rem 2.4rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    justify-self: end;
    flex: 0 0 auto;
  }

  .uploaded-file {
    height: fit-content;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    flex: 0 1 auto;
    word-break: break-all;
    text-align: right;
    vertical-align: middle;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    padding-top: 5.6rem;
    padding-bottom: 3.2rem;
  }

  .btn-back,
  .btn-confirm {
    width: 15.6rem;
    height: 4.8rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .btn-confirm {
    background: #cd0715;
  }

  @media only screen and (min-width: 720px) {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0rem;
    border-bottom: 2px solid #262626;

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3.2rem;
    }

    .half-element {
      width: 47%;
    }

    .btn-wrapper {
      padding-top: 4rem;
    }
  }
`;

const AboutUsForm = ({ buttonName, setRefetch, refetch }) => {
  // Create the states/setState for the above fields
  const [aboutUsItem, setAboutUsItem] = useState(null);
  const [bannerImage, setBannerImage] = useState([]);
  const [sectionOneLeftImg, setSectionOneLeftImg] = useState([]);
  const [sectionOneRightImg, setSectionOneRightImg] = useState([]);
  const [sectionTwoImg, setSectionTwoImg] = useState([]);
  const [sectionThreeImg, setSectionThreeImg] = useState([]);
  const [sectionFourLeftImg, setSectionFourLeftImg] = useState([]);
  const [sectionFourRightImg, setSectionFourRightImg] = useState([]);
  const charLength = 1023;
  // create the formSchema based on the above schema
  const formSchema = yup.object().shape({
    banner_title: yup
      .string()
      .required('標題為必填')
      .max(charLength, `標題最長為${charLength}字`),
    banner_subtitle: yup
      .string()
      .required('副標題為必填')
      .max(charLength, `副標題最長為${charLength}字`),
    banner_paragraph: yup
      .string()
      .required('副標題內容為必填')
      .max(charLength, `副標題內容最長為${charLength}字`),
    section_1_subtitle: yup
      .string()
      .required('章節1標題為必填')
      .max(charLength, `章節1標題最長為${charLength}字`),
    section_1_description: yup
      .string()
      .required('章節1內容為必填')
      .max(charLength, `章節1內容最長為${charLength}字`),
    section_2_subtitle: yup
      .string()
      .required('章節2標題為必填')
      .max(charLength, `章節2標題最長為${charLength}字`),
    section_2_description: yup
      .string()
      .required('章節2內容為必填')
      .max(charLength, `章節2內容最長為${charLength}字`),
    section_3_subtitle: yup
      .string()
      .required('章節3標題為必填')
      .max(charLength, `章節3標題最長為${charLength}字`),
    section_3_description: yup
      .string()
      .required('章節3內容為必填')
      .max(charLength, `章節3內容最長為${charLength}字`),
    section_4_subtitle: yup
      .string()
      .required('章節4標題為必填')
      .max(charLength, `章節4標題最長為${charLength}字`),
    section_4_description: yup
      .string()
      .required('章節4內容為必填')
      .max(charLength, `章節4內容最長為${charLength}字`),
  });

  const formMethods = useForm({ resolver: yupResolver(formSchema) });
  const {
    setValue,
    formState: { isDirty },
    handleSubmit,
  } = formMethods;

  const { isLoading, sendRequest } = useHttpClient();
  const [responseMessage, setResponseMessage] = useState('');

  const setDefaultItem = async () => {
    // console.log("setDefaultItem");
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/aboutUs',
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );
      console.log('responseData', responseData);
      setAboutUsItem(responseData[0]);
      const currentItem = responseData[0];
      if (!!currentItem) {
        for (let name in currentItem) {
          setValue(name, currentItem[name]);
        }
        // Use Promise.all to wait for all the urlToObject calls to complete
        const [
          bannerImageObj,
          sectionOneLeftImgObj,
          sectionOneRightImgObj,
          sectionTwoImgObj,
          sectionThreeImgObj,
          sectionFourLeftImgObj,
          sectionFourRightImgObj,
        ] = await Promise.all([
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.banner_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_1_left_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_1_right_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_2_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_3_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_4_left_img}`,
          ),
          await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_4_right_img}`,
          ),
        ]);
        setBannerImage([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.banner_img}`,
            file: bannerImageObj,
          },
        ]);
        setSectionOneLeftImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_1_left_img}`,
            file: sectionOneLeftImgObj,
          },
        ]);
        setSectionOneRightImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_1_right_img}`,
            file: sectionOneRightImgObj,
          },
        ]);
        setSectionTwoImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_2_img}`,
            file: sectionTwoImgObj,
          },
        ]);
        setSectionThreeImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_3_img}`,
            file: sectionThreeImgObj,
          },
        ]);
        setSectionFourLeftImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_4_left_img}`,
            file: sectionFourLeftImgObj,
          },
        ]);
        setSectionFourRightImg([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/aboutUs/${currentItem.section_4_right_img}`,
            file: sectionFourRightImgObj,
          },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isDirty) {
      setResponseMessage('');
    }
  }, [isDirty]);

  const auth = useContext(AuthContext);

  const onSubmit = async data => {
    try {
      const formData = new FormData();
      if (!!aboutUsItem) {
        formData.append('id', data.id);
      }
      formData.append('banner_title', data.banner_title);
      formData.append('banner_subtitle', data.banner_subtitle);
      formData.append('banner_paragraph', data.banner_paragraph);
      formData.append('section_1_subtitle', data.section_1_subtitle);
      formData.append('section_1_description', data.section_1_description);
      formData.append('section_2_subtitle', data.section_2_subtitle);
      formData.append('section_2_description', data.section_2_description);
      formData.append('section_3_subtitle', data.section_3_subtitle);
      formData.append('section_3_description', data.section_3_description);
      formData.append('section_4_subtitle', data.section_4_subtitle);
      formData.append('section_4_description', data.section_4_description);

      // Note: the order of the images is important
      formData.append('image', bannerImage[0].file);
      formData.append('image', sectionOneLeftImg[0].file);
      formData.append('image', sectionOneRightImg[0].file);
      formData.append('image', sectionTwoImg[0].file);
      formData.append('image', sectionThreeImg[0].file);
      formData.append('image', sectionFourLeftImg[0].file);
      formData.append('image', sectionFourRightImg[0].file);

      const responseData = await sendRequest(
        !!aboutUsItem
          ? process.env.REACT_APP_BACKEND_URL + '/admin/aboutUs/update'
          : process.env.REACT_APP_BACKEND_URL + '/admin/aboutUs/new',
        'POST',
        formData,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );

      // Set the response message
      setResponseMessage(responseData.message);
      setRefetch(true);
    } catch (err) {
      console.log(err);
      setResponseMessage(
        '請確認所有必填欄位皆已填寫，圖片上傳正確，且格式正確',
      );
    }
  };

  const setDefaultItemWrapper = async () => {
    await setDefaultItem();
  };

  useEffect(() => {
    if (!refetch) return;
    setDefaultItemWrapper();
    setRefetch(false);
  }, [refetch, responseMessage]);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <StyledSection>
          <FormProvider {...formMethods}>
            <form
              className="sub-form-wrapper"
              onSubmit={handleSubmit(onSubmit)}
            >
              <ul className="sub-form">
                {/** BANNER */}
                <FormInput
                  title="標題"
                  name="banner_title"
                  example="ABOUT US"
                />
                <FormInput
                  title="副標題"
                  name="banner_subtitle"
                  example="De Tomaso Partners Up With Capricorn Group To Build The Upcoming P72 Supercar At The Nürburgring"
                  half={false}
                />
                <FormInput
                  half={false}
                  title="副標題內容"
                  name="banner_paragraph"
                  example="The history of De Tomaso is steeped in international collaborations. Take the perennially popular Pantera for example, which was the result of an American designer drawing sports cars in Turin (Tom Tjaarda at Ghia) to be powered by V8s from Ohio (the Ford Cleveland engines), all under the direction of Alejandro de Tomaso, who came to Europe from Argentina to build sports and racing cars in the late 1950s. Today, the company’s tradition of global partnerships is being carried forward with a brand new strategic partnership, this time between the revived De Tomaso marque and longtime German motorsport chassis and component developer, capricorn Group (responsible in part for the Porsche 919 LMP1 triple Le Mans-winner, for starters)."
                />
                <ImageUpload
                  title="標題圖片"
                  maxNumber={1}
                  maxFileSize={8000000}
                  images={bannerImage}
                  onChangeImages={setBannerImage}
                />
                {/** SECTION 1 */}
                <FormInput
                  title="章節1 標題"
                  name="section_1_subtitle"
                  example="ABOUT"
                />
                <FormInput
                  title="章節1 內容"
                  half={false}
                  name="section_1_description"
                  example="911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。"
                />
                <div className="flex">
                  <ImageUpload
                    title="章節1 左圖"
                    maxNumber={1}
                    maxFileSize={8000000}
                    images={sectionOneLeftImg}
                    onChangeImages={setSectionOneLeftImg}
                  />
                  <ImageUpload
                    title="章節1 右圖"
                    maxNumber={1}
                    maxFileSize={8000000}
                    images={sectionOneRightImg}
                    onChangeImages={setSectionOneRightImg}
                  />
                </div>

                {/** SECTION 2 */}
                <FormInput
                  title="章節2 標題"
                  name="section_2_subtitle"
                  example="OUR TEAM"
                />
                <FormInput
                  title="章節2 內容"
                  half={false}
                  name="section_2_description"
                  example="911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。"
                />
                <ImageUpload
                  title="章節2 圖片"
                  maxNumber={1}
                  maxFileSize={8000000}
                  images={sectionTwoImg}
                  onChangeImages={setSectionTwoImg}
                />

                {/** SECTION 3 */}
                <FormInput
                  title="章節3 標題"
                  name="section_3_subtitle"
                  example="WORK"
                />
                <FormInput
                  title="章節3 內容"
                  half={false}
                  name="section_3_description"
                  example="911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。"
                />
                <ImageUpload
                  title="章節3 圖片"
                  maxNumber={1}
                  maxFileSize={8000000}
                  images={sectionThreeImg}
                  onChangeImages={setSectionThreeImg}
                />

                {/** SECTION 4 */}
                <FormInput
                  title="章節4 標題"
                  name="section_4_subtitle"
                  example="WORK"
                />
                <FormInput
                  title="章節4 內容"
                  half={false}
                  name="section_4_description"
                  example="911 Carrera 的優雅的輪廓以其標誌性「保時捷飛躍線（Porsche Flyline）」使得 911 Carrera 外型顯而易見，自 1963 年以來就塑造了保時捷品牌的 DNA，皆是具有驚人性能的跑車。"
                />
                <div className="flex">
                  <ImageUpload
                    title="章節4 左圖"
                    maxNumber={1}
                    maxFileSize={8000000}
                    images={sectionFourLeftImg}
                    onChangeImages={setSectionFourLeftImg}
                  />
                  <ImageUpload
                    title="章節4 右圖"
                    maxNumber={1}
                    maxFileSize={8000000}
                    images={sectionFourRightImg}
                    onChangeImages={setSectionFourRightImg}
                  />
                </div>
              </ul>
            </form>
          </FormProvider>
          <section className="sub-form-wrapper">
            {responseMessage && <ErrorMessage message={responseMessage} />}
            <div className="btn-wrapper">
              <button className="btn-confirm" onClick={handleSubmit(onSubmit)}>
                儲存
              </button>
            </div>
          </section>
        </StyledSection>
      )}
    </React.Fragment>
  );
};

export default AboutUsForm;
