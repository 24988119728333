import React from 'react';
import ImageUploading from 'react-images-uploading';
import CloseButton from 'react-bootstrap/CloseButton';
import styled from 'styled-components';

const StyledImageUpload = styled.div`
  *:not(.btn-close) {
    margin: 0;
    padding: 0;
    background: transparent;
  }

  .image-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    margin: 0;
    background: none;
  }

  .upload__image-wrapper {
    width: 100%;
    height: 100%;
    padding-bottom: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .btn-upload {
    width: 11.2rem;
    height: 4rem;
    border: 2px solid #ffffff;
    border-radius: 30px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;

    :active {
      background: #cd0715;
    }
  }

  .image-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1.24rem;
  }

  .image-item {
    position: relative;
    width: 5.54rem;
    height: 5.54rem;
    object-fit: fill;
  }

  img {
    width: 5.54rem;
    height: 5.54rem;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0rem;
    width: 5.54rem;
    height: 5.54rem;
    background: rgba(0, 0, 0, 0.3);
  }

  .btn-close {
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    width: 1rem;
    height: 1rem;
  }

  .image-count-recorder {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .upload-btn-wrapper {
    display: flex;
    gap: 2.4rem;
    padding: 0 1.6rem;
  }

  @media only screen and (min-width: 720px) {
    .upload-btn-wrapper {
      padding: 0;
    }

    .upload__image-wrapper {
      max-width: 66.4rem;
      margin: auto;
    }
  }
`;

const ImageUpload = ({
  title,
  maxNumber,
  maxFileSize,
  images,
  onChangeImages,
}) => {
  const handleChangeImages = imageList => {
    onChangeImages(imageList);
    console.log('handleChangeImages in ImageUpload :>>>>>>>', imageList);
  };

  return (
    <StyledImageUpload>
      <h3 className="image-title">{title}</h3>
      <ImageUploading
        multiple
        value={images}
        maxFileSize={maxFileSize}
        onChange={(imageList, addUpdateIndex) => {
          // console.log(imageList, addUpdateIndex);
          handleChangeImages(imageList);
        }}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemove }) => (
          <div className="upload__image-wrapper">
            <div className="image-items">
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image['data_url']} alt="" />
                  <div className="overlay"></div>
                  <CloseButton
                    variant="white"
                    onClick={() => onImageRemove(index)}
                  ></CloseButton>
                </div>
              ))}
            </div>
            <div className="upload-btn-wrapper">
              <button
                className="btn-upload"
                onClick={e => {
                  e.preventDefault();
                  onImageUpload();
                }}
              >
                上傳圖片
              </button>
              <p className="image-count-recorder">
                {imageList.length}/{maxNumber} (圖片每張需小於
                {maxFileSize / 1000000}MB)
              </p>
            </div>
          </div>
        )}
      </ImageUploading>
    </StyledImageUpload>
  );
};

export default ImageUpload;
