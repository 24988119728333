import styled from 'styled-components';
import alertIcon from 'assets/icons/alertIcon.svg';
import EmptyInventory from './EmptyInventory';
import InventoryCardPanel from './InventoryCardPanel';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { InventoryComponentRenderState } from 'pages/States';
import { AuthContext } from 'shared/context/auth-context';
import { useHttpClient } from 'shared/hooks/http-hook';
import React, { useState, useContext, useEffect } from 'react';

const InventoryWrapper = styled.section`
  .top-title-exclamation {
    display: flex;
    align-items: flex-start;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #969696;
    gap: 0.8rem;
    margin-bottom: 4.5rem;
  }

  .top-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2.8rem;
    justify-content: space-between;
  }

  .btn-add-new-address-top {
    gap: 10px;
    width: 80px;
    height: 40px;
    border: 2px solid #ffffff;
    border-radius: 30px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }

  @media only screen and (min-width: 900px) {
    .top-title-exclamation {
      align-items: center;
    }

    .btn-add-new-address-top {
      margin-right: 1.6rem;
    }
  }
`;

const StyledReactSearchAutocomplete = styled.div`
  margin: 3rem;
  * {
    background-color: transparent;
  }
  .ellipsis {
    color: #ffffff;
    span {
      color: #ffffff;
    }
  }

  .wrapper {
    /* display: none; */
    .line {
      display: none;
    }
    div:nth-child(2) {
      display: none;
    }
  }
`;

const Inventory = ({
  setShowInventory,
  inventoryItems,
  showInventory,
  setInventoryItems,
  setCurrentInventoryItem,
  refetch,
  setRefetch,
}) => {
  const [searchedInventoryItems, setSearchedInventoryItems] =
    useState(inventoryItems);

  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const auth = useContext(AuthContext);

  const fetchInventoryItems = async () => {
    try {
      if (auth.isLoggedIn) {
        if (showInventory === InventoryComponentRenderState.AllInventories) {
          try {
            const responseData = await sendRequest(
              process.env.REACT_APP_BACKEND_URL + '/admin/inventory',
              'GET',
              null,
              {
                Authorization: 'Bearer ' + auth.token,
              },
            );
            setInventoryItems(responseData);
          } catch (err) {
            console.log('err :>> ', err);
          }
        }
      }
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInventoryItemsWrapper = async () => {
    await fetchInventoryItems();
  };

  useEffect(() => {
    if (!refetch) return;
    fetchInventoryItemsWrapper();
    setRefetch(false);
  }, [refetch]);

  useEffect(() => {
    setSearchedInventoryItems(inventoryItems);
  }, [inventoryItems]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log("onSearch", string, results);
    if (string === '') {
      setSearchedInventoryItems(inventoryItems);
    } else if (results.length > 0) {
      setSearchedInventoryItems(results);
    }
  };
  // console.log("inventoryItems", inventoryItems);
  // console.log("searchedInventoryItems", searchedInventoryItems);

  const handleOnHover = result => {
    // the item hovered
    // console.log(result);
  };

  const handleOnSelect = item => {
    // the item selected
    // console.log("onSelect", item);
    // console.log(item);
  };

  const handleOnFocus = () => {
    // console.log("Focused");
  };

  const formatResult = item => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>
          {item.title}
        </span>
      </>
    );
  };

  return (
    <React.Fragment>
      {!isLoading && (
        <InventoryWrapper>
          <div className="top-title-exclamation">
            <img className="alert-icon" src={alertIcon} alt=""></img>
            下列在庫車款會即時更新在網站上,您可以在此修改在庫車款
          </div>
          <StyledReactSearchAutocomplete>
            <ReactSearchAutocomplete
              items={inventoryItems}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              maxResults={inventoryItems.length}
              formatResult={formatResult}
              showNoResultsText="無搜尋結果"
              placeholder="輸入關鍵字"
              resultStringKeyName="title"
              maxLength={30}
              fuseOptions={{
                keys: [
                  'title',
                  'appearance',
                  'back',
                  'brand',
                  'breaking',
                  'description_1',
                  'description_2',
                  'drive',
                  'engine',
                  'fuel',
                  'gear',
                  'model',
                  'price',
                  'seats',
                  'status',
                  'year',
                  'transmission',
                ],
              }}
              styling={{
                backgroundColor: '#000000',
                borderRadius: '2.4rem',
                border: '2px solid #969696',
                fontFamily: 'Albert Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '1.6rem',
                color: '#ffffff',
                iconColor: '#ffffff',
                lineHeight: '1.8rem',
                hoverBackgroundColor: '#1c1c1c',
                zIndex: '998',
                lineColor: '#969696',
                placeholderColor: '#969696',
              }}
            />
          </StyledReactSearchAutocomplete>

          <div className="top-title-wrapper">
            <h1 className="top-title-text">目前在網站上的車款</h1>
            <button
              onClick={() =>
                setShowInventory(InventoryComponentRenderState.NewInventory)
              }
              className="btn-add-new-address-top"
            >
              新增
            </button>
          </div>
          {!inventoryItems.length && (
            <EmptyInventory label="尚未設定在庫車款" />
          )}
          {searchedInventoryItems.length && (
            <InventoryCardPanel
              setRefetch={setRefetch}
              setShowInventory={setShowInventory}
              inventoryItems={inventoryItems}
              setInventoryItems={setInventoryItems}
              setCurrentInventoryItem={setCurrentInventoryItem}
              searchedInventoryItems={searchedInventoryItems}
            />
          )}
        </InventoryWrapper>
      )}
    </React.Fragment>
  );
};

export default Inventory;
