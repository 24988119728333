// // import React from "react";
// import ReactDOM from "react-dom/client";
// import { RouterProvider } from "react-router-dom";
// import router from "./App.js";
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   // <React.StrictMode>
//   <RouterProvider router={router} />
//   // </React.StrictMode>
// );

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RouterOutlet } from './App';

createRoot(document.getElementById('root')).render(
  <Router>
    <Routes>
      <Route path="*" element={<RouterOutlet />} />
    </Routes>
  </Router>,
);
