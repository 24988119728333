import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'shared/components/FormElements/ErrorMessage';
import { AuthContext } from 'shared/context/auth-context';
import { useHttpClient } from 'shared/hooks/http-hook';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import * as yup from 'yup';

const StyledLoginForm = styled.form`
  margin: auto;
  padding: 4rem 1.6rem 0 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .title {
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 3.2rem;
  }

  .input-elements {
    width: 100%;
    max-width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
    margin: 3.2rem auto 1.2rem auto;
  }

  .input-element {
    width: 100%;
    background: #010001;
    border: 1px solid #969696;
    border-radius: 8px;

    height: 4.8rem;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 1.3rem 1.6rem;
    :focus {
      outline: none;
    }
  }

  .login {
    width: 100%;
    max-width: 36rem;
    height: 48px;
    background: #cd0715;
    border-radius: 8px;

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: none;
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }

  .registered {
    display: none;
    align-self: flex-start;
    padding-left: 1.6rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #ff3846;
    margin-top: -2rem;
    margin-bottom: 0rem;
  }

  .not-registered-text {
    display: flex;
  }

  .not-registered-input-element {
    border: 1px solid #ff3846;
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [loginError, setLoginError] = useState('');
  useEffect(() => {
    if (auth.isLoggedIn) {
      navigate('/home');
    }
  }, [auth, navigate]);

  const { isLoading, sendRequest } = useHttpClient();
  const onSubmit = async data => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/login',
        'POST',
        JSON.stringify({
          email: data.email,
          password: data.password,
        }),
        {
          'Content-Type': 'application/json',
        },
      );
      auth.login(responseData.id, responseData.token);
      navigate('/home');
    } catch (err) {
      console.log(err);
      setLoginError('Email或密碼錯誤。');
    }
  };

  const formSchema = yup.object().shape({
    email: yup.string().required('Email為必填').email('請輸入正確Email'),
    password: yup
      .string()
      .required('密碼為必填')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        '密碼為8到32個字元,至少各有1個大小寫英文字,一個數字,和一個特殊符號',
      )
      .max(32, '密碼最長為32個字元'),
  });

  const {
    register,
    formState: { errors, isValidating },
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) });

  useEffect(() => {
    setLoginError('');
  }, [isValidating]);
  return (
    <>
      {isLoading && <LoadingSpinner asOverlay />}
      <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
        <h1 className="title">管理員登入</h1>
        <div className="input-elements">
          <input
            className={`input-element ${
              auth.isLoggedIn ? '' : 'not-registered-input-element'
            }`}
            type="email"
            autoComplete="on"
            placeholder="Email"
            {...register('email')}
          />
          {errors.email && <ErrorMessage message={errors.email.message} />}
          {loginError && <ErrorMessage message={loginError} />}
          <input
            className="input-element"
            type="password"
            autoComplete="on"
            placeholder="密碼"
            {...register('password')}
          />
          {errors.password && (
            <ErrorMessage message={errors.password.message} />
          )}
        </div>
        <button className="login" onClick={handleSubmit(onSubmit)}>
          登入
        </button>
      </StyledLoginForm>
    </>
  );
};

export default Login;
