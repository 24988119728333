import React, { useState, useContext, useEffect, memo } from 'react';
import styled from 'styled-components';
import ErrorMessage from 'shared/components/FormElements/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import { AuthContext } from 'shared/context/auth-context';
import FormInput from 'shared/components/FormElements/FormInput';
import ImageUpload from 'shared/components/FormElements/ImageUpload';
import { urlToObject } from 'shared/utils/urlToObject';

const StyledHome = styled.section`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .sub-form-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    width: 100%;
    max-width: 640px;
    background: rgba(1, 0, 1, 0.4);
    backdrop-filter: blur(4px);
    border-radius: 1.6rem;
    margin: auto;
  }

  .sub-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.4rem;
    padding: 0;
    background: none;
  }

  .form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background: none;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0;
    margin: 0;
    background: none;
  }

  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    background: none;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .more-description {
    padding: 1.2rem 1.6rem;
    height: 9.6rem;
    resize: none;
  }

  .upload-file {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: 0.2rem solid #ffffff;
    border-radius: 3rem;
    background: none;
    padding: 1rem 2.4rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    justify-self: end;
    flex: 0 0 auto;
  }

  .uploaded-file {
    height: fit-content;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    flex: 0 1 auto;
    word-break: break-all;
    text-align: right;
    vertical-align: middle;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    padding-top: 5.6rem;
    padding-bottom: 3.2rem;
  }

  .btn-back,
  .btn-confirm {
    width: 15.6rem;
    height: 4.8rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .btn-confirm {
    background: #cd0715;
  }

  @media only screen and (min-width: 720px) {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0rem;
    border-bottom: 2px solid #262626;

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3.2rem;
    }

    .half-element {
      width: 47%;
    }

    .btn-wrapper {
      padding-top: 4rem;
    }
  }
`;

const HomeForm = memo(({ buttonName, refetch, setRefetch }) => {
  // Create the states/setState for the above fields
  const [homeItem, setHomeItem] = useState(null);
  const [bannerImage, setBannerImage] = useState([]);
  const [topicImages, setTopicImages] = useState([]);
  const charLength = 1023;
  // create the formSchema based on the above schema
  const formSchema = yup.object().shape({
    car_title: yup
      .string()
      .required('車名為必填')
      .max(charLength, `車名最長為${charLength}字`),
    slogan: yup
      .string()
      .required(`標語為必填（最長${charLength}字）`)
      .max(charLength, `標語最長為${charLength}字`),
    slogan_sentence_1: yup
      .string()
      .required('標語句1為必填')
      .max(charLength, `標語句1最長為${charLength}字`),
    slogan_sentence_2: yup
      .string()
      .required('標語句2為必填')
      .max(charLength, `標語句2最長為${charLength}字`),
    slogan_sentence_3: yup
      .string()
      .required('標語句3為必填')
      .max(charLength, `標語句3最長為${charLength}字`),
    slogan_sentence_4: yup
      .string()
      .required('標語句4為必填')
      .max(charLength, `標語句4最長為${charLength}字`),
    banner_speed: yup
      .string()
      .required('加速秒速為必填')
      .max(charLength, `加速秒速最長為${charLength}字`),
    banner_mileage: yup
      .string()
      .required('里程數為必填')
      .max(charLength, `里程數最長為${charLength}字`),
    banner_drive: yup
      .string()
      .required('驅動為必填')
      .max(charLength, `驅動最長為${charLength}字`),
  });

  const formMethods = useForm({ resolver: yupResolver(formSchema) });
  const {
    setValue,
    formState: { isDirty },
    handleSubmit,
  } = formMethods;

  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const [responseMessage, setResponseMessage] = useState('');

  const fetchHome = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/home',
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );
      setHomeItem(responseData[0]);
      const currentItem = responseData[0];
      if (!!currentItem) {
        for (let name in currentItem) {
          setValue(name, currentItem[name]);
        }
        const file = await urlToObject(
          `${process.env.REACT_APP_DATA_URL}/uploads/home/${currentItem.banner_image}`,
        );
        setBannerImage([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/home/${currentItem.banner_image}`,
            file: file,
          },
        ]);
        setTopicImages(
          await Promise.all(
            currentItem.topics_images.map(async item => {
              const file = await urlToObject(
                `${process.env.REACT_APP_DATA_URL}/uploads/home/${item}`,
              );
              return {
                data_url: `${process.env.REACT_APP_DATA_URL}/uploads/home/${item}`,
                file: file,
              };
            }),
          ),
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHomeWrapper = async () => {
    await fetchHome();
  };

  useEffect(() => {
    if (!refetch) return;
    fetchHomeWrapper();
    setRefetch(false);
  }, [refetch]);

  useEffect(() => {
    if (isDirty) {
      setResponseMessage('');
    }
  }, [isDirty]);

  const auth = useContext(AuthContext);

  const onSubmit = async data => {
    try {
      const formData = new FormData();
      if (!!homeItem) {
        formData.append('id', data.id);
      }
      formData.append('car_title', data.car_title);
      formData.append('slogan', data.slogan);
      formData.append('slogan_sentence_1', data.slogan_sentence_1);
      formData.append('slogan_sentence_2', data.slogan_sentence_2);
      formData.append('slogan_sentence_3', data.slogan_sentence_3);
      formData.append('slogan_sentence_4', data.slogan_sentence_4);
      formData.append('banner_speed', data.banner_speed);
      formData.append('banner_mileage', data.banner_mileage);
      formData.append('banner_drive', data.banner_drive);

      // Note: the order of the images is important
      formData.append('image', bannerImage[0].file);
      for (const image of topicImages) {
        formData.append('image', image.file);
      }
      const responseData = await sendRequest(
        !!homeItem
          ? process.env.REACT_APP_BACKEND_URL + '/admin/home/update'
          : process.env.REACT_APP_BACKEND_URL + '/admin/home/new',
        'POST',
        formData,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );

      // Set the response message
      setResponseMessage(responseData.message);
      await fetchHome();
    } catch (err) {
      console.log(err);
      setResponseMessage(
        '請確認所有必填欄位皆已填寫，圖片上傳正確，且格式正確',
      );
    }
  };

  useEffect(() => {
    let isMounted = true;

    const setDefaultItem = async () => {
      if (isMounted) {
        await fetchHome();
      }
    };
    setIsLoading(true);
    setDefaultItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseMessage]);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <StyledHome>
          <FormProvider {...formMethods}>
            <form
              className="sub-form-wrapper"
              onSubmit={handleSubmit(onSubmit)}
            >
              <ul className="sub-form">
                <FormInput
                  title="車名"
                  name="car_title"
                  example="Porsche 911 Carrera GTS"
                />
                <FormInput title="標語" name="slogan" example="為超越而生" />
                <FormInput
                  title="標語句1（短）"
                  name="slogan_sentence_1"
                  example="為尖端騎士而造"
                />
                <FormInput
                  title="標語句2（長）"
                  name="slogan_sentence_2"
                  half={false}
                  example="無數細節只為實現你對極致性能的渴望"
                />
                <FormInput
                  title="標語句3（短）"
                  name="slogan_sentence_3"
                  example="流暢滑順、靈活敏捷"
                />
                <FormInput
                  title="標語句4（長）"
                  name="slogan_sentence_4"
                  half={false}
                  example="騎乘的五感驚豔，從此躍入全新里程碑"
                />
                <FormInput
                  title="0-100公里/小時，加速秒數"
                  name="banner_speed"
                  example="3.3"
                />
                <FormInput
                  title="里程數，公里"
                  name="banner_mileage"
                  example="619"
                />
                <FormInput
                  title="驅動系統"
                  name="banner_drive"
                  example="全輪驅動"
                />
              </ul>
            </form>
          </FormProvider>
          <section className="sub-form-wrapper">
            <div className="sub-form">
              <ImageUpload
                title="車輛大圖"
                maxNumber={1}
                maxFileSize={8000000}
                images={bannerImage}
                onChangeImages={setBannerImage}
              />
              <ImageUpload
                title="7張專題圖片"
                maxNumber={7}
                maxFileSize={8000000}
                images={topicImages}
                onChangeImages={setTopicImages}
              />
            </div>
            {responseMessage && <ErrorMessage message={responseMessage} />}
            <div className="btn-wrapper">
              <button className="btn-confirm" onClick={handleSubmit(onSubmit)}>
                儲存
              </button>
            </div>
          </section>
        </StyledHome>
      )}
    </React.Fragment>
  );
});

export default HomeForm;
