import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import ErrorMessage from 'shared/components/FormElements/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import radioSelect from 'assets/icons/radio-select.svg';
import radioSelected from 'assets/icons/radio-select-clicked.svg';
import { useForm, FormProvider } from 'react-hook-form';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import FormInput from 'shared/components/FormElements/FormInput';
import ImageUpload from 'shared/components/FormElements/ImageUpload';
import { AuthContext } from 'shared/context/auth-context';
import { InventoryComponentRenderState } from 'pages/States';
import { urlToObject } from 'shared/utils/urlToObject';

const StyledInventory = styled.section`
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .sub-form-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    width: 100%;
    max-width: 640px;
    background: rgba(1, 0, 1, 0.4);
    backdrop-filter: blur(4px);
    border-radius: 1.6rem;
    margin: auto;
  }

  .radio-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.6rem 0 3rem 0;
  }

  .radio-select {
    margin-left: 0.1rem;
    appearance: none;
    background: url(${radioSelect});
    background-size: contain;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
    :checked {
      background: url(${radioSelected});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .radio-title {
    height: 20px;

    /* Regular 16 */

    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: center;

    /* Text/ff */

    color: #ffffff;
    margin: auto 1.2rem;
    display: inline-block;
  }

  .sub-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.4rem;
    padding: 0;
    background: none;
  }

  .form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background: none;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0;
    margin: 0;
    background: none;
  }

  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    background: none;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .more-description {
    padding: 1.2rem 1.6rem;
    height: 9.6rem;
    resize: none;
  }

  .upload-file {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: 0.2rem solid #ffffff;
    border-radius: 3rem;
    background: none;
    padding: 1rem 2.4rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    justify-self: end;
    flex: 0 0 auto;
  }

  .uploaded-file {
    height: fit-content;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    flex: 0 1 auto;
    word-break: break-all;
    text-align: right;
    vertical-align: middle;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    padding-top: 5.6rem;
    padding-bottom: 3.2rem;
  }

  .btn-back,
  .btn-confirm {
    width: 15.6rem;
    height: 4.8rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .btn-confirm {
    background: #cd0715;
  }

  @media only screen and (min-width: 720px) {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0rem;
    border-bottom: 2px solid #262626;

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3.2rem;
    }

    .half-element {
      width: 47%;
    }

    .btn-wrapper {
      padding-top: 4rem;
    }
  }
`;

const InventoryForm = ({
  buttonName,
  setRefetch,
  refetch,
  showInventory,
  setShowInventory,
  currentInventoryItem,
  setCurrentInventoryItem,
  setInventoryItems,
}) => {
  // Create the states/setState for the above fields
  const [display_status, setDisplayStatus] = useState('on_sale');
  const [image, setImage] = useState([]);
  const [angleImages, setAngleImages] = useState([]);
  const [subImage1, setSubImage1] = useState([]);
  const [subImage2, setSubImage2] = useState([]);
  const [selectedImg, setSelectedImg] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const currentYear = new Date().getFullYear();
  const charLength = 1023;
  // create the formSchema based on the above schema
  const formSchema = yup.object().shape({
    brand: yup
      .string()
      .required('廠牌為必填')
      .max(charLength, `車廠最長為${charLength}字`),
    title: yup
      .string()
      .required('車名為必填')
      .max(charLength, `車名最長為${charLength}字`),
    price: yup
      .string()
      .required('價格為必填')
      .max(charLength, `價格最長為${charLength}字`),
    speed: yup
      .string()
      .required('加速秒數為必填')
      .max(charLength, `加速秒數最長為${charLength}字`),
    mileage: yup
      .string()
      .required('里程數為必填')
      .max(charLength, `里程數最長為${charLength}字`),
    drive: yup
      .string()
      .required('驅動系統為必填')
      .max(charLength, `驅動系統最長為${charLength}字`),
    year: yup
      .number()
      .required('車型年份為必填')
      .max(currentYear, '車型年份最高為今年年份'),
    engine: yup
      .string()
      .required('引擎為必填')
      .max(charLength, `引擎最長為${charLength}字`),
    transmission: yup
      .string()
      .required('傳輸為必填')
      .max(charLength, `傳輸最長為${charLength}字`),
    steering: yup.string().max(charLength, `轉向最長為${charLength}字`),
    breaking: yup.string().max(charLength, `煞車最長為${charLength}字`),
    appearance: yup
      .string()
      .required('外觀顏色為必填')
      .max(charLength, `外觀顏色最長為${charLength}字`),
    interior: yup
      .string()
      .max(charLength, `內飾顏色和材料最長為${charLength}字`),
    back: yup.string().max(charLength, `背部最長為${charLength}字`),
    subtitle: yup
      .string()
      .required('副標題為必填')
      .max(charLength, `副標題最長為${charLength}字`),
    description_1: yup
      .string()
      .required('上圖描述為必填')
      .max(charLength, `描述最長為${charLength}字`),
    description_2: yup
      .string()
      .required('下圖描述為必填')
      .max(charLength, `描述最長為${charLength}字`),
  });

  const formMethods = useForm({ resolver: yupResolver(formSchema) });
  const {
    register,
    setValue,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = formMethods;

  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const [responseMessage, setResponseMessage] = useState('');
  const auth = useContext(AuthContext);

  const setDefaultItem = async currentItem => {
    if (showInventory === InventoryComponentRenderState.EditInventory) {
      for (let name in currentItem) {
        setValue(name, currentItem[name]);
      }
      const file = await urlToObject(
        `${process.env.REACT_APP_DATA_URL}/inventory/${currentItem.image}`,
      );
      setImage([
        {
          data_url: `${process.env.REACT_APP_DATA_URL}/inventory/${currentItem.image}`,
          file: file,
        },
      ]);
      setDisplayStatus(currentItem.display_status);

      setAngleImages(
        await Promise.all(
          currentItem.angles.map(async item => {
            const file = await urlToObject(
              `${process.env.REACT_APP_DATA_URL}/inventory/${item}`,
            );
            return {
              data_url: `${process.env.REACT_APP_DATA_URL}/inventory/${item}`,
              file,
            };
          }),
        ),
      );
      setSubImage1([
        {
          data_url: `${process.env.REACT_APP_DATA_URL}/inventory/${currentItem.sub_img_1}`,
          file: await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/inventory/${currentItem.sub_img_1}`,
          ),
        },
      ]);
      setSubImage2([
        {
          data_url: `${process.env.REACT_APP_DATA_URL}/inventory/${currentItem.sub_img_2}`,
          file: await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/inventory/${currentItem.sub_img_2}`,
          ),
        },
      ]);
      setSelectedImg([
        {
          data_url: `${process.env.REACT_APP_DATA_URL}/inventory/${currentItem.selected_img}`,
          file: await urlToObject(
            `${process.env.REACT_APP_DATA_URL}/inventory/${currentItem.selected_img}`,
          ),
        },
      ]);
    }
  };

  const fetchInventoryItems = async () => {
    try {
      if (auth.isLoggedIn) {
        setIsLoading(true);
        if (
          showInventory === InventoryComponentRenderState.AllInventories ||
          showInventory === InventoryComponentRenderState.EditInventory
        ) {
          try {
            const responseData = await sendRequest(
              process.env.REACT_APP_BACKEND_URL + '/admin/inventory',
              'GET',
              null,
              {
                Authorization: 'Bearer ' + auth.token,
              },
            );
            setInventoryItems(responseData);
            const currentItemId = localStorage.getItem('inventoryItemId');
            const currentItem = responseData.find(
              item => item.id === Number(currentItemId),
            );
            await setDefaultItem(currentItem);
          } catch (err) {
            console.log('err :>> ', err);
            setIsFetching(false);
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      console.log('error :>> ', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInventoryItemsWrapper = async () => {
    await fetchInventoryItems();
  };

  useEffect(() => {
    if (!refetch) return;
    fetchInventoryItemsWrapper();
    setRefetch(false);
  }, [refetch]);

  useEffect(() => {
    if (isDirty) {
      setResponseMessage('');
    }
  }, [isDirty]);

  const onSubmit = async data => {
    try {
      const formData = new FormData();
      if (buttonName === '儲存') {
        formData.append('id', data.id);
      }
      formData.append('display_status', display_status);
      formData.append('brand', data.brand);
      formData.append('title', data.title);
      formData.append('price', data.price);
      formData.append('speed', data.speed);
      formData.append('mileage', data.mileage);
      formData.append('drive', data.drive);
      formData.append('year', data.year);
      formData.append('engine', data.engine);
      formData.append(
        'transmission',
        !!data.transmission ? data.transmission : '-',
      );
      formData.append('steering', !!data.steering ? data.steering : '-');
      formData.append('breaking', !!data.breaking ? data.breaking : '-');
      formData.append('appearance', data.appearance);
      formData.append('interior', !!data.interior ? data.interior : '-');
      formData.append('back', !!data.back ? data.back : '-');
      formData.append('subtitle', data.subtitle);
      formData.append('description_1', data.description_1);
      formData.append('description_2', data.description_2);
      // Note: the order of the images is important
      formData.append('image', image[0].file);
      formData.append('image', subImage1[0].file);
      formData.append('image', subImage2[0].file);
      formData.append('image', selectedImg[0].file);
      for (const angleImage of angleImages) {
        formData.append('image', angleImage.file);
      }
      const responseData = await sendRequest(
        buttonName === '新增'
          ? process.env.REACT_APP_BACKEND_URL + '/admin/inventory/new'
          : process.env.REACT_APP_BACKEND_URL + '/admin/inventory/update',
        'POST',
        formData,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );

      // Reset the form
      if (buttonName === '新增') {
        reset({
          display_status: '',
          brand: '',
          title: '',
          price: '',
          speed: '',
          mileage: '',
          drive: '',
          year: '',
          engine: '',
          transmission: '',
          steering: '',
          breaking: '',
          appearance: '',
          interior: '',
          back: '',
          subtitle: '',
          description_1: '',
          description_2: '',
        });

        // Reset the states
        setImage([]);
        setAngleImages([]);
        setSubImage1([]);
        setSubImage2([]);
        setSelectedImg([]);
      }
      // Set the response message
      setRefetch(true);
      setResponseMessage(responseData.message);
    } catch (err) {
      console.log(err);
      setResponseMessage(
        '請確認所有必填欄位皆已填寫，圖片上傳正確，且格式正確',
      );
    }
  };

  return (
    <React.Fragment>
      {(isLoading || isFetching) && <LoadingSpinner asOverlay />}
      {!isLoading && !isFetching && (
        <StyledInventory>
          <FormProvider {...formMethods}>
            <form
              className="sub-form-wrapper"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="sub-form-radio">
                <h3 className="element-title">顯示狀態</h3>
                <div className="radio-form">
                  <input
                    className="radio-select"
                    type="radio"
                    value="sold_show"
                    id="sold_show"
                    {...register('display_status')}
                    checked={display_status === 'sold_show'}
                    onChange={e => setDisplayStatus(e.currentTarget.value)}
                  />
                  <label className="radio-title">已售並顯示 &ensp;</label>
                  <input
                    className="radio-select"
                    type="radio"
                    value="sold_noshow"
                    id="sold_noshow"
                    {...register('display_status')}
                    checked={display_status === 'sold_noshow'}
                    onChange={e => setDisplayStatus(e.currentTarget.value)}
                  />
                  <label className="radio-title">已售不顯示</label>
                  <input
                    className="radio-select"
                    type="radio"
                    value="on_sale"
                    id="on_sale"
                    {...register('display_status')}
                    checked={display_status === 'on_sale'}
                    onChange={e => setDisplayStatus(e.currentTarget.value)}
                  />
                  <label className="radio-title">待售並顯示</label>
                  <input
                    className="radio-select"
                    type="radio"
                    value="on_edit"
                    id="on_edit"
                    {...register('display_status')}
                    checked={display_status === 'on_edit'}
                    onChange={e => setDisplayStatus(e.currentTarget.value)}
                  />
                  <label className="radio-title">編輯中不顯示</label>
                </div>
              </div>
              <ul className="sub-form">
                <FormInput title="廠牌" name="brand" example="Ferrari" />
                <FormInput
                  title="車名"
                  name="title"
                  example="2022 Ferrari 296 GTB"
                />
                <FormInput title="價格" name="price" example="1,688萬" />
                <FormInput
                  title="0-100公里/小時，加速秒數"
                  name="speed"
                  example="3.3"
                />
                <FormInput title="里程數，公里" name="mileage" example="619" />
                <FormInput title="驅動系統" name="drive" example="全輪驅動" />
                <FormInput title="車型年份" name="year" example="2022" />
                <FormInput title="引擎" name="engine" example="396" />
                <FormInput title="傳輸" name="transmission" example="4速手動" />
                <FormInput
                  title="轉向（選填）"
                  name="steering"
                  example="動力輔助"
                />
                <FormInput
                  title="煞車（選填）"
                  name="breaking"
                  example="碟式"
                />
                <FormInput title="外觀顏色" name="appearance" example="紅色" />
                <FormInput
                  title="內飾顏色和材料（選填）"
                  name="interior"
                  example="碳纖維"
                />
                <FormInput title="背部（選填）" name="back" example="掀背" />
                <FormInput
                  title="副標題"
                  name="subtitle"
                  half={false}
                  textarea={true}
                  example="Ferrari 296 GTB是躍馬雙座後中置引擎配置Berlinetta車型的跨時代鉅作。"
                />
                <FormInput
                  title="上圖描述"
                  name="description_1"
                  half={false}
                  textarea={true}
                  example="作為Ferrari法拉利首輛搭載V6引擎的躍馬公路跑車，在120° 
                          V6渦輪增壓內燃機引擎與混合動力系統的完美搭配"
                />
                <FormInput
                  title="下圖描述"
                  name="description_2"
                  half={false}
                  textarea={true}
                  example="最大綜效輸出功率高達830 CV，
                          成就超乎想像的強悍性能及獨樹一格的悅耳聲浪，
                          無論是極限操控或是日常駕駛均帶來純粹的駕馭激情！"
                />
              </ul>
            </form>
          </FormProvider>
          <section className="sub-form-wrapper">
            <div className="sub-form">
              <ImageUpload
                title="車輛去背圖片"
                maxNumber={1}
                maxFileSize={8000000}
                images={image}
                onChangeImages={setImage}
              />
              <ImageUpload
                title="更多角度圖片"
                maxNumber={12}
                maxFileSize={8000000}
                images={angleImages}
                onChangeImages={setAngleImages}
              />
              <ImageUpload
                title="上圖"
                maxNumber={1}
                maxFileSize={8000000}
                images={subImage1}
                onChangeImages={setSubImage1}
              />
              <ImageUpload
                title="中間圖"
                maxNumber={1}
                maxFileSize={8000000}
                images={selectedImg}
                onChangeImages={setSelectedImg}
              />
              <ImageUpload
                title="下圖"
                maxNumber={1}
                maxFileSize={8000000}
                images={subImage2}
                onChangeImages={setSubImage2}
              />
            </div>
            {responseMessage && <ErrorMessage message={responseMessage} />}
            <div className="btn-wrapper">
              <button
                onClick={e => {
                  e.preventDefault();
                  setShowInventory(
                    InventoryComponentRenderState.AllInventories,
                  );
                  setRefetch(true);
                }}
                className="btn-back"
              >
                返回
              </button>
              <button className="btn-confirm" onClick={handleSubmit(onSubmit)}>
                {buttonName}
              </button>
            </div>
          </section>
        </StyledInventory>
      )}
    </React.Fragment>
  );
};

export default InventoryForm;
