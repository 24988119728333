export const urlToObject = async (url, type = 'image') => {
  try {
    const response = await fetch(url, {
      mode: 'cors',
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    const blob = await response.blob();
    const file = new File(
      [blob],
      type === 'image' ? 'image.png' : 'video.mp4',
      type === 'image' ? { type: 'image/png' } : { type: 'video/mp4' },
    );
    return file;
  } catch (error) {
    console.log(error);
  }
};
