import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import ErrorMessage from 'shared/components/FormElements/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useHttpClient } from 'shared/hooks/http-hook';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import { AuthContext } from 'shared/context/auth-context';
import ImageUpload from 'shared/components/FormElements/ImageUpload';
import { useMemo } from 'react';
import { urlToObject } from 'shared/utils/urlToObject';

const StyledSection = styled.section`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #969696;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }

  .sub-form-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    width: 100%;
    max-width: 640px;
    background: rgba(1, 0, 1, 0.4);
    backdrop-filter: blur(4px);
    border-radius: 1.6rem;
    margin: auto;
  }

  .sub-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.4rem;
    padding: 0;
    background: none;
  }

  .flex {
    display: flex;
  }

  .form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    background: none;
  }

  .element-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 0;
    margin: 0;
    background: none;
  }

  .element-input {
    width: 100%;
    padding: 0 1.6rem;
    background: #010001;
    height: 4.8rem;
    border: 1px solid #969696;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    background: none;

    ::placeholder {
      color: #969696;
    }

    :focus {
      outline: none;
    }
  }

  .more-description {
    padding: 1.2rem 1.6rem;
    height: 9.6rem;
    resize: none;
  }

  .upload-file {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border: 0.2rem solid #ffffff;
    border-radius: 3rem;
    background: none;
    padding: 1rem 2.4rem;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    justify-self: end;
    flex: 0 0 auto;
  }

  .uploaded-file {
    height: fit-content;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    flex: 0 1 auto;
    word-break: break-all;
    text-align: right;
    vertical-align: middle;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    padding-top: 5.6rem;
    padding-bottom: 3.2rem;
  }

  .btn-back,
  .btn-confirm {
    width: 15.6rem;
    height: 4.8rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: 'Albert Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .btn-confirm {
    background: #cd0715;
  }

  @media only screen and (min-width: 720px) {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0rem;
    border-bottom: 2px solid #262626;

    .sub-form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3.2rem;
    }

    .half-element {
      width: 47%;
    }

    .btn-wrapper {
      padding-top: 4rem;
    }
  }
`;

const BackgroundForm = ({ buttonName, refetch, setRefetch }) => {
  // Create the states/setState for the above fields
  const [bgItem, setBgItem] = useState(null);
  const [serviceEval, setServiceEval] = useState([]);
  const [serviceSale, setServiceSale] = useState([]);
  const [contact, setContact] = useState([]);
  const [recruitment, setRecruitment] = useState([]);

  // create the formSchema based on the above schema
  const formSchema = yup.object().shape({});

  const formMethods = useForm({ resolver: yupResolver(formSchema) });
  const { handleSubmit } = formMethods;

  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const [responseMessage, setResponseMessage] = useState('');

  const isFormValid = useMemo(() => {
    return (
      !!serviceEval.length &&
      !!serviceSale.length &&
      !!contact.length &&
      !!recruitment.length
    );
  }, [
    contact.length,
    recruitment.length,
    serviceEval.length,
    serviceSale.length,
  ]);

  useEffect(() => {
    if (isFormValid) {
      setResponseMessage('');
    }
  }, [isFormValid]);

  const auth = useContext(AuthContext);

  const setDefaultItem = async () => {
    // console.log("setDefaultItem");
    try {
      setIsLoading(true);
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/bg',
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );
      console.log('responseData', responseData);
      setBgItem(responseData[0]);
      const currentItem = responseData[0];
      if (!!currentItem) {
        setServiceEval([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/bg/${currentItem.service_eval_bg}`,
            file: await urlToObject(
              `${process.env.REACT_APP_DATA_URL}/uploads/bg/${currentItem.service_eval_bg}`,
            ),
          },
        ]);
        setServiceSale([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/bg/${currentItem.service_sale_bg}`,
            file: await urlToObject(
              `${process.env.REACT_APP_DATA_URL}/uploads/bg/${currentItem.service_sale_bg}`,
            ),
          },
        ]);
        setContact([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/bg/${currentItem.contact_bg}`,
            file: await urlToObject(
              `${process.env.REACT_APP_DATA_URL}/uploads/bg/${currentItem.contact_bg}`,
            ),
          },
        ]);
        setRecruitment([
          {
            data_url: `${process.env.REACT_APP_DATA_URL}/uploads/bg/${currentItem.recruitment_bg}`,
            file: await urlToObject(
              `${process.env.REACT_APP_DATA_URL}/uploads/bg/${currentItem.recruitment_bg}`,
            ),
          },
        ]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const setDefaultItemWrapper = async () => {
    await setDefaultItem();
  };

  const onSubmit = async data => {
    if (!isFormValid) {
      setResponseMessage('請填寫所有欄位');
      return;
    }

    try {
      const formData = new FormData();
      if (!!bgItem) {
        formData.append('id', bgItem.id);
      }
      // Note: the order of the images is important
      formData.append('image', serviceEval[0].file);
      formData.append('image', serviceSale[0].file);
      formData.append('image', contact[0].file);
      formData.append('image', recruitment[0].file);
      setIsLoading(true);
      const responseData = await sendRequest(
        !!bgItem
          ? process.env.REACT_APP_BACKEND_URL + '/admin/bg/update'
          : process.env.REACT_APP_BACKEND_URL + '/admin/bg/new',
        'POST',
        formData,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );

      // Set the response message
      setResponseMessage(responseData.message);
      setRefetch(true);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setResponseMessage(
        '請確認所有必填欄位皆已填寫，圖片上傳正確，且格式正確',
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!refetch) return;
    setDefaultItemWrapper();
    setRefetch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, responseMessage]);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <StyledSection>
          <FormProvider {...formMethods}>
            <form
              className="sub-form-wrapper"
              onSubmit={handleSubmit(onSubmit)}
            >
              <ul className="sub-form">
                {/** BANNER */}
                <ImageUpload
                  title="線上服務 - 線上估價背景圖"
                  maxNumber={1}
                  maxFileSize={8000000}
                  images={serviceEval}
                  onChangeImages={setServiceEval}
                />
                <ImageUpload
                  title="線上服務 - 委託銷售背景圖"
                  maxNumber={1}
                  maxFileSize={8000000}
                  images={serviceSale}
                  onChangeImages={setServiceSale}
                />
                <ImageUpload
                  title="聯絡我們背景圖"
                  maxNumber={1}
                  maxFileSize={8000000}
                  images={contact}
                  onChangeImages={setContact}
                />
                <ImageUpload
                  title="人才招募背景圖"
                  maxNumber={1}
                  maxFileSize={8000000}
                  images={recruitment}
                  onChangeImages={setRecruitment}
                />
              </ul>
            </form>
          </FormProvider>
          <section className="sub-form-wrapper">
            {responseMessage && <ErrorMessage message={responseMessage} />}
            <div className="btn-wrapper">
              <button className="btn-confirm" onClick={handleSubmit(onSubmit)}>
                儲存
              </button>
            </div>
          </section>
        </StyledSection>
      )}
    </React.Fragment>
  );
};

export default BackgroundForm;
