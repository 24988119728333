import { useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [refetch, setRefetch] = useState(false);
  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(
    async (url, method = 'GET', data = null, headers = {}) => {
      setIsLoading(true);
      const source = axios.CancelToken.source();
      activeHttpRequests.current.push(source);

      try {
        const response = await axios({
          url,
          method,
          data,
          headers,
          cancelToken: source.token,
        });

        const responseData = response.data;

        activeHttpRequests.current = activeHttpRequests.current.filter(
          cancelToken => cancelToken !== source,
        );

        setIsLoading(false);
        return responseData;
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
        throw err;
      }
    },
    [],
  );

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach(source => {
        source.cancel('Request cancelled by cleanup');
      });
    };
  }, []);

  return { isLoading, error, sendRequest, setIsLoading, refetch, setRefetch };
};
