import React, { useState, useContext, useEffect } from 'react';
import LoadingSpinner from 'shared/components/UIElements/LoadingSpinner';
import collapseBefore from 'assets/images/collapseBefore.svg';
import ReactPaginate from 'react-paginate';
import Accordion from 'react-bootstrap/Accordion';
import { useHttpClient } from 'shared/hooks/http-hook';
import { AuthContext } from 'shared/context/auth-context';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import styled from 'styled-components';

const AccordionWrapper = styled(Accordion)`
  .accordion-item {
    border: none;
  }
  .accordion-body {
    background: #1c1c1c;
    border-radius: 0px;
    > ul {
      display: flex;
      flex-wrap: wrap;
      background: #010001;
      border-radius: 16px;
      padding: 1.2rem 1.6rem;

      > li {
        width: 100%;
        display: flex;
        gap: 3.2rem;
        background: none;
        padding: 0.8rem;
        label {
          min-width: 8rem;
          font-family: 'Albert Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #969696;
          background: none;
        }

        h5 {
          font-family: 'Albert Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
          background: none;
          overflow-wrap: anywhere;
          margin-left: auto;
          text-align: right;
          margin-bottom: 0;
        }
      }
    }

    > div {
      background: none;
      > div {
        display: flex;
        gap: 3.2rem;
        padding: 0 2rem;
        background: none;

        img {
          width: 6.4rem;
          height: 6.4rem;
          object-fit: contain;
          background: none;
        }

        .name-price-wrapper {
          display: flex;
          flex-direction: column;
          background: none;
          justify-items: space-between;
          align-items: flex-start;
          width: 100%;

          h2 {
            font-family: 'Albert Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #ffffff;
            background: none;
          }

          h3 {
            font-family: 'Albert Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: left;
            display: flex;
            align-items: center;
            color: #ffffff;
            background: none;
          }
        }
      }
    }
  }

  .accordion-button {
    width: 100%;
    background: none;
    border: none;
    padding: 0 1.25rem;

    &.collapsed {
      margin: 0;
      border: none;
      ::after {
        position: absolute;
        right: 3rem;
        background-image: url(${collapseBefore});
        background-size: 2rem 2rem;
        background-position-x: 50%;
      }
    }

    &:not(.collapsed)::after {
      position: absolute;
      right: 3rem;
      background-image: url(${collapseBefore});
      background-size: 2rem 2rem;
      background-position-x: 50%;
    }

    :focus {
      box-shadow: none;
      border-color: black;
      background: #1c1c1c;
      border-radius: 0px;
    }

    > div {
      padding: 1.2rem 0;
      margin: 0 1.2rem;
      border-bottom: 0.2rem solid #262626;
      width: 100%;
      height: 6.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 0rem;
      gap: 3rem;
      background: none;

      h6 {
        width: 33%;
        font-family: 'Albert Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        margin: auto;
        background: none;
        word-wrap: break-word;
      }

      .date-time {
        display: flex;
        flex-direction: column;
        width: 33%;
        margin: auto;
        padding: 0;
        background: none;
        > h6 {
          width: 100%;
        }
      }
    }
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .accordion-button {
    &.collapsed:focus {
      border-radius: 0px 0px 0px 0px;
    }
    :hover {
      border-radius: 0px 0px 0px 0px;
      background: #1c1c1c;
    }
  }
  @media only screen and (min-width: 900px) {
    .accordion-body {
      border-radius: 0px 0px 16px 16px;
      > ul {
        margin: 1rem 1rem;
        > li {
          width: 50%;
          label {
            font-size: 16px;
            line-height: 20px;
          }
          h5 {
            font-size: 16px;
            line-height: 20px;
            margin-left: 0;
          }
        }
      }
      > div {
        > div {
          img {
            width: 8rem;
            height: 8rem;
          }
          .name-price-wrapper {
            flex-direction: row;
            align-items: center;
            h2 {
              font-size: 16px;
              line-height: 24px;
            }
            h3 {
              font-size: 16px;
              line-height: 24px;
              text-align: right;
              margin-left: auto;
            }
          }
        }
      }
    }
    .accordion-button {
      :focus {
        border-radius: 16px 16px 0px 0px;
      }
      > div {
        h6 {
          font-size: 16px;
          line-height: 24px;
        }
        .date-time {
          flex-direction: row;
          gap: 1rem;
        }
      }
    }
  }
`;

const StyledReactSearchAutocomplete = styled.div`
  margin: 3rem;
  * {
    background-color: transparent;
  }
  .ellipsis {
    color: #ffffff;
    span {
      color: #ffffff;
    }
  }

  .wrapper {
    /* display: none; */
    .line {
      display: none;
    }
    div:nth-child(2) {
      display: none;
    }
  }
`;

const CarItems = ({ currentItems, cars }) => {
  return (
    <React.Fragment>
      <AccordionWrapper className="border-0">
        {currentItems.map((reservation, index) => (
          <Accordion.Item key={index} className="border-0" eventKey={index}>
            <Accordion.Header className="border-0">
              <div>
                <div className="date-time">
                  <h6>
                    {reservation.reservation_date}
                    &emsp;
                    {reservation.reservation_time.slice(0, 5)}
                  </h6>
                </div>
                <h6>{reservation.name}</h6>
                <h6>{reservation.reservation_way}</h6>
                <h6>{reservation.reserved_cars.length}</h6>
              </div>
            </Accordion.Header>
            <Accordion.Body className="border-0">
              <ul>
                <li>
                  <label>姓名</label>
                  <h5>{reservation.name}</h5>
                </li>
                <li>
                  <label>公司名稱</label>
                  <h5>{reservation.company}</h5>
                </li>
                <li>
                  <label>手機號碼</label>
                  <h5>{reservation.mobile}</h5>
                </li>
                <li>
                  <label>Email</label>
                  <h5>{reservation.email}</h5>
                </li>
                {reservation.reservation_way === '預約地點' && (
                  <li>
                    <label>預約地址</label>
                    <h5>
                      {reservation.reservation_zip_code}
                      {reservation.reservation_city}
                      {reservation.reservation_district}
                      {reservation.reservation_street}
                    </h5>
                  </li>
                )}
              </ul>
              <div>
                {reservation.reserved_cars.map((carIndex, index) => (
                  <div key={index}>
                    <img src={cars.get(carIndex)?.img} alt="car" />
                    <div className="name-price-wrapper">
                      <h2>{cars.get(carIndex)?.title}</h2>
                      <h3>
                        {' '}
                        {'NT$' +
                          (
                            parseInt(
                              cars
                                .get(carIndex)
                                ?.price.replace('萬', '')
                                .replace(',', ''),
                            ) * 10000
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </AccordionWrapper>
    </React.Fragment>
  );
};

const HistoryWrapper = styled.div`
  && {
    width: 100%;
    height: 5.2rem;
    background: #1c1c1c;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    header {
      width: 25%;
      font-family: 'Albert Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #969696;
      background: none;
    }
  }
`;

const HistoryPaginateWrapper = styled(ReactPaginate)`
  && {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 8px;
    padding-top: 3.2rem;
  }

  .page-link {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background-color: transparent;
    border-radius: 14px;
    border: transparent;
    color: #969696;
    user-select: none;
  }

  .page-active {
    background: #1c1c1c;
    border-radius: 14px;
    border: transparent;
    box-shadow: none;
    color: white;
  }

  .page-previous,
  .page-next {
    font-weight: 200;
    font-size: 18px;
    color: white;
    text-decoration: none;
    user-select: none;
  }
`;

const History = ({ refetch, setRefetch }) => {
  const itemsPerPage = 20;
  // console.log(item);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;

  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // const currentItems = item.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(item.length / itemsPerPage);

  // // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % item.length;
  //   setItemOffset(newOffset);
  // };

  const [searchedReservationData, setSearchedReservationData] = useState([]);
  const [reservationData, setReservationData] = useState([]);
  const [cars, setCars] = useState([]);
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest, setIsLoading } = useHttpClient();

  const fetchHistory = async () => {
    try {
      setIsLoading(true);
      const reservationData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/admin/reservation/history/all',
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      );

      // replace the reservation_way to be the Chinese version
      reservationData.forEach(item => {
        if (item.reservation_way === 'scheduled') {
          item.reservation_way = '預約地點';
        } else {
          item.reservation_way = '到店賞車';
        }
        item.reservation_date = item.reservation_date.replaceAll('-', '/');
      });

      // console.log("reservationData", reservationData);
      setReservationData(reservationData);
      setSearchedReservationData(reservationData);

      // Get the inventory data
      const inventoryData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/inventory/all',
        'GET',
      );

      // Construct items from the inventoryItems
      // console.log("inventoryData", inventoryData);
      const inventoryCars = new Map(
        inventoryData.map(item => {
          return [
            item.id,
            {
              img: `${process.env.REACT_APP_DATA_URL}/inventory/${item.image}`,
              title: item.title,
              price: item.price,
            },
          ];
        }),
      );
      // console.log('inventoryCars', inventoryCars);
      setCars(inventoryCars);
      setIsLoading(false);
    } catch (err) {
      console.log('err', err);
      setIsLoading(false);
    }
  };

  const fetchHistoryWrapper = async () => {
    await fetchHistory();
  };

  useEffect(() => {
    if (!refetch) return;
    console.log('Fetch reservation historical data');
    fetchHistoryWrapper();
    setRefetch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // Note: due to `Every returned JSX element must be only one JSX element`,
  //       Tab cannot be included in this function
  const currentItems = searchedReservationData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(searchedReservationData.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset =
      (event.selected * itemsPerPage) % searchedReservationData.length;
    setItemOffset(newOffset);
  };

  // console.log("reservationData", reservationData);
  // console.log("cars", cars);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log('onSearch', string, results);
    if (string === '') {
      setSearchedReservationData(reservationData);
    } else if (results.length > 0) {
      setSearchedReservationData(results);
    }
  };

  const handleOnHover = result => {
    // the item hovered
    // console.log(result);
  };

  const handleOnSelect = item => {
    // the item selected
    // console.log("onSelect", item);
    // console.log(item);
  };

  const handleOnFocus = () => {
    // console.log("Focused");
  };

  const formatResult = item => {
    return (
      <React.Fragment>
        <span style={{ display: 'block', textAlign: 'left' }}>
          {item.reservation_date} &nbsp; {item.reservation_time} &nbsp;{' '}
          {item.name}
        </span>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && !!reservationData?.length && !!cars?.size && (
        <React.Fragment>
          <StyledReactSearchAutocomplete>
            <ReactSearchAutocomplete
              items={reservationData}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              maxResults={reservationData.length}
              formatResult={formatResult}
              showNoResultsText="無搜尋結果"
              placeholder="輸入關鍵字"
              resultStringKeyName="name"
              fuseOptions={{
                keys: [
                  'company',
                  'contact_city',
                  'contact_district',
                  'contact_street',
                  'contact_zip_code',
                  'email',
                  'mobile',
                  'name',
                  'reservation_city',
                  'reservation_date',
                  'reservation_time',
                  'reservation_way',
                  'reservation_district',
                ],
              }}
              styling={{
                backgroundColor: '#000000',
                borderRadius: '2.4rem',
                border: '2px solid #969696',
                fontFamily: 'Albert Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '1.6rem',
                color: '#ffffff',
                iconColor: '#ffffff',
                lineHeight: '1.8rem',
                hoverBackgroundColor: '#1c1c1c',
                zIndex: '998',
                lineColor: '#969696',
                placeholderColor: '#969696',
              }}
            />
          </StyledReactSearchAutocomplete>
          <HistoryWrapper>
            <header>預約時間</header>
            <header>預約姓名</header>
            <header>預約方式</header>
            <header>賞車數量</header>
          </HistoryWrapper>
          <CarItems currentItems={currentItems} cars={cars} />
          <HistoryPaginateWrapper
            className="pagination"
            pageLinkClassName="page-link"
            activeLinkClassName="page-active"
            previousLinkClassName="page-previous"
            nextLinkClassName="page-previous"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default History;
